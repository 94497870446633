import React, {useState, useEffect, useContext} from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import qrGif from '../assets/scan-qr-code-gif.gif';
import handCode from '../assets/hand-qrcodes.png';
import freemium from '../assets/freemium.png';
import premium from '../assets/premium.png';
import shaking from '../assets/shaking.jpg';
import pic from '../assets/self2.png'
import QRgif from '../assets/image.gif';
import { writeSubscription } from '../firebase';
import { useLocation, useNavigate } from 'react-router-dom';
import ToastService from 'react-material-toast';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import { Backdrop, CircularProgress} from '@mui/material';
import { AddCircle, Email, GitHub, Instagram, LinkedIn, Phone, RemoveCircle, Twitter, WhatsApp } from '@mui/icons-material';
import { PaystackButton  } from 'react-paystack';
import { SharedFunctions } from '../shared/Functions';

const toast = ToastService.new({
    place: "topRight",
    duration: 1,
    maxCount: 1,
  });

export default function Welcome() {
    const {user_id, userSubscriptionData, nextBillingDate, userInfo, sendEmails, duration, setDuration, premiumMonthlyPrice, finalAmount, setFinalAmount, subscription, subscriptionLogo, premiumCalc, publicKey, secretKey, token, baseURL, formatName} = useContext(SharedFunctions);
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const subscriptionType = sessionStorage.getItem("subscriptionType");
    const [freeIsDisabled, setFreeIsDisabled] = useState(false);
    const [preIsDisabled, setPreIsDisabled] = useState(false);
    const [IsDisabled, setIsDisabled] = useState(true)
    const [isDisabledColor, setIsDisabledColor] = useState("btn btn-secondary");
    const [freeBtnColor, setFreeBtnColor] = useState("btn btn-primary");
    const [preBtnColor, setPreBtnColor] = useState("btn btn-primary");
    const [target, setTarget] = useState("");
    const [toggle, setToggle] = useState("");
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userPhone, setUserPhone] = useState("");
    const [userMessage, setUserMessage] = useState("");
    const today = new Date();
    const formattedToday = today.toLocaleDateString();
    

    const image = "https://i.imgur.com/9SeQofR.png";
    const title = "Subscription Upgraded";
    const subject =  "Subscription Upgraded";
    const msg = `Your subscription has been upgraded to Premium. Enjoy unlimited creation of dynamic QR codes. \nYour next billing date is ${nextBillingDate()}.`

    const image1 = "https://i.imgur.com/9SeQofR.png";
    const title1 = "Subscription Renewed";
    const subject1 =  "Subscription Renewed";
    const msg1 = `Your subscription has been Renewed. Enjoy unlimited creation of dynamic QR codes. \nYour next billing date is ${nextBillingDate()}.`



    const onPremiumClick = () => {
        if (sessionStorage.getItem("subscriptionType") === "Freemium" || sessionStorage.getItem("subscriptionType") === "Premium"){
            setTarget("#payment");
            setToggle("modal");
            premiumCalc();
        }else{
            sessionStorage.setItem("straightPremium", true)
            navigate("/signup/email")
        }
    };

    
    useEffect(() => {
        // console.log(userSubscriptionData[6] === "Premium" && new Date().toLocaleDateString() === nextBillingDate())
        if (sessionStorage.getItem("subscriptionType") === "Freemium" || sessionStorage.getItem("subscriptionType") === "Premium"){
            setTarget("#payment");
            setToggle("modal");
        }
        // eslint-disable-next-line
    }, [userSubscriptionData]);

    useEffect(() => {
        if(userSubscriptionData.subscriptionType === "Premium" && new Date(formattedToday) >= nextBillingDate()){
            setPreBtnColor("btn btn-primary");
            setPreIsDisabled(false)
            setFreeBtnColor("btn btn-secondary");
            setFreeIsDisabled(true)
        }else if (subscriptionType === 'Freemium'){
            setFreeBtnColor("btn btn-secondary");
            setFreeIsDisabled(true)
        }else if (subscriptionType === 'Premium'){
            setPreBtnColor("btn btn-secondary");
            setPreIsDisabled(true)
            setFreeBtnColor("btn btn-secondary");
            setFreeIsDisabled(true)
        }
        // eslint-disable-next-line
    }, [userSubscriptionData]);
    
  useEffect(() => {
    if (location.hash === '#pricing') {
      const pricingSection = document.getElementById('pricing');
      if (pricingSection) {
        pricingSection.scrollIntoView();
      }
    } else if (location.hash === '#about'){
      const aboutSection = document.getElementById('about');
      if(aboutSection){
        aboutSection.scrollIntoView();
      }
    }else if (location.hash === '#contact'){
      const contactSection = document.getElementById('contact');
      if(contactSection){
        contactSection.scrollIntoView();
      }
    }
  }, [location]);


    const sendEmail = async (userName, userEmail, userPhone, userMessage, e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            var templateParams = {
                to: 'christiankpegah@gmail.com',
                name: userName,
                email: userEmail,
                phone: userPhone,
                message: userMessage
            };
    
            const response = await fetch(`${baseURL}/api/send-email-enquiry`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(templateParams),
            });
    
            if (!response.ok) {
                // const errorData = await response.json(); 
                toast.error("Message Not Sent!");
            } else {
                setUserName("");
                setUserEmail("");
                setUserPhone("");
                setUserMessage("");
                toast.success("Message Sent Successfully!!");
            }
        } catch (error) {
            console.error('Error occurred while sending email:', error);
        }finally{
            setIsLoading(false)
        }
    };

    const increaseSize = () => {
        const newDuration = duration + 1;
        setDuration(newDuration);
        setFinalAmount((premiumMonthlyPrice * newDuration) * 100)
    };

    const decreaseSize = () => {
        const newDuration = Math.max(duration - 1, 1);
        setDuration(newDuration);
        setFinalAmount(finalAmount - (premiumMonthlyPrice * 100))
    };
    

    const config = {
        reference: (new Date()).getTime().toString(),
        email: userInfo.email,
        amount: finalAmount, 
        publicKey: publicKey,
        secretKey: secretKey,
        currency: 'GHS',
    };

    const handlePaystackSuccessAction = (reference) => {
        sessionStorage.setItem("subscriptionType", subscription)
        const amt = finalAmount/100
        writeSubscription(user_id, subscription, amt, reference.message, reference.status, reference.transaction, reference.trxref, reference.reference, duration );
        if (userSubscriptionData.subscriptionType === "Premium" && new Date(formattedToday) >= nextBillingDate()){
            sendEmails(userInfo.email, formatName(userInfo.name), image1, title1, subject1, msg1)
        }else{
            sendEmails(userInfo.email, formatName(userInfo.name), image, title, subject, msg)
        }
        setIsDisabled(false);
        setIsDisabledColor("btn btn-success")
    };
  
    const handlePaystackCloseAction = () => {
    setIsDisabled(true);
    setIsDisabledColor("btn btn-secondary")
    console.log('closed')
    };
  
    const componentProps = {
        ...config,
        text: 'Make Payment',
        onSuccess: (reference) => handlePaystackSuccessAction(reference),
        onClose: handlePaystackCloseAction,
    };

    
    
      
  return (
    <div>
        <NavBar />
        <div className="">
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
            <div className="banner py-4">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-sm-6 welcome-text">
                            <h6>Welcome to QR GenN</h6>
                            <h1>Scan, Share, and Connect!!</h1>
                            <h2>Your QR Code Gateway to Seamless Communication!!!</h2>
                            {token ? (
                                <></>
                            ):(
                               <a href="/signup/email" className='btn btn-primary' >Get Started</a> 
                            ) }
                        </div>
                        <div className="col-sm-6">
                            <img className='qr-gif' src={qrGif} alt="QR GIF" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="intro mb-3">
                <div className="container">
                    <h1 className='text-center pb-5' >I'm new to QR Codes. What should I know?</h1>
                    <div className="row">
                        <div className="col-sm-6 handqr-code">
                            <img src={handCode} alt="hand qr code" />
                        </div>
                        <div className="col-sm-6">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                       <h4>What is a QR Code?</h4>
                                    </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <p className='qr-def'>
                                            QR Code is a two-dimensional version of the barcode, typically made up of black and white pixel patterns. Denso Wave, a Japanese subsidiary of the Toyota supplier Denso, developed them for marking components in order to accelerate logistics processes for their automobile production. Now, it has found its way into mobile marketing with the widespread adoption of smartphones. "QR" stands for "Quick Response", which refers to the instant access to the information hidden in the Code.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        <h4>What are the benefits of using QR Codes?</h4>
                                    </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <p className='qr-def'>
                                            They are gaining popularity because of their versatility. You can use them to gather feedback to improve your products or services, increase customer engagement with images or videos, or even promote your business via events and coupons. All of these can be done with just a single scan!
                                        </p>
                                    </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        <h4>How do I scan QR Codes?</h4>
                                    </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <p className='qr-def'>
                                            Depending on your device, you might already have a built-in QR Code reader or scanner. Open the camera app on your mobile phone and hold it over a Code for a few seconds until a notification pops up. If this doesn't happen, check your settings and see if QR Code scanning is enabled. Still not working? Don't worry, all you have to do now is install third-party QR Code readers from your app stores.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="scan py-4">
                <div className="container">
                    <h2 className='text-center' >How to scan a QR Code from an image on iPhone?</h2>
                        <div className="row mb-3 ms-2 me-2">
                            <div className="col-sm card shadow border-0 me-2 mb-3 py-4">
                                <p>Open the picture/screenshot on your phone gallery.</p>
                            </div>
                            <div className="col-sm card shadow border-0 me-2 mb-3 py-4">
                                <p>Click the live text detection on the bottom right corner.</p>
                            </div>
                            <div className="col-sm card shadow border-0 me-2 mb-3 py-4">
                                <p>Click the QR Code and follow the prompt against it.</p>
                            </div>
                        </div>
                    <h2 className='text-center'>How to scan QR Code from a picture on Android?</h2>
                    
                    <div className="row mb-3 ms-2 me-2">
                        <div className="col-sm card shadow border-0 me-2 mb-3">
                            <div className="">
                                <div className="card-body">
                                    <h5  className='fw-bold'>With Google Lens</h5>
                                    <ol>
                                        <li>Launch the Google Lens application</li>
                                        <li>Choose the image from the gallery</li>
                                        <li>A popup link will appear once the app detects the QR Code</li>
                                        <li>Click the link to be redirected to the website or content</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm card shadow border-0 me-2 mb-3">
                            <div className="">
                                <div className="card-body">
                                    <h5 className='fw-bold'>In phone gallery</h5>
                                    <ol>
                                        <li>Open the picture on your phone gallery</li>
                                        <li>On the top right corner, tap the Google Lens icon</li>
                                        <li>A link will appear once it detects the QR Code</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm card shadow border-0 mb-3">
                            <div className="">
                                <div className="card-body">
                                    <h5 className='fw-bold'>Using Bixby vision</h5>
                                    <ol>
                                        <li>Open the Gallery app after taking a snapshot of your QR Code.</li>
                                        <li>Open the QR Code image.</li>
                                        <li>Press and hold the Bixby Vision icon.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>
                        Make sure Lens is selected before letting Bixby Vision scan the QR Code. Post scanning, a clickable pop-up page leads you to the destination content. If you took a quick photo and now can't scan the QR code, try to sharpen image online to fix the blur.
                    </p>
                </div>
            </div>
            <div className='pricing py-4' id="pricing" >
                
                <div className="container">
                    <h1 className='text-center pb-4' >Pricing</h1>
                    <h6 className='mb-3 text-center'>
                        Unlock the full potential of QR Code generation with our feature-rich plans designed to meet your specific needs.
                    </h6>
                    <div className="row justify-content-between ms-2 me-2">
                        <div className="col-sm-2"></div>
                        <div className="col-sm-3 mb-3 card shadow border-0">
                            <div className="">
                                <div className='subs-badge text-center'>
                                    <img src={freemium} className="card-img-top" alt="..."/>
                                </div>
                                <div className="card-body">
                                    <h3 className='text-center'>GH₵0.00</h3>
                                    <ul>
                                        <li><p>2 Dynamic QR Codes</p></li>
                                        <li><p>Unlimited number of scans</p></li>
                                        <li><p>Limited number of QR Code downloads</p></li>
                                    </ul>
                                    <div className='mt-5 text-center'>
                                        <button className={freeBtnColor} disabled={freeIsDisabled} onClick={() => navigate("/signup/email")} >Get Started</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 mb-3 card shadow border-0">
                            <div className="">
                                <div className='subs-badge text-center'>
                                    <img src={premium} className="card-img-top" alt="..."/>
                                </div>
                                <div className="card-body">
                                    <h3 className='text-center' >GH₵{(premiumMonthlyPrice).toFixed(2)}/month</h3>
                                    <ul>
                                        <li><p>Unlimited Dynamic QR Codes</p></li>
                                        <li><p>Unlimited number of scans</p></li>
                                        <li><p>Unlimited number of QR Code downloads</p></li>
                                    </ul>
                                    <div className='mt-5 text-center'>
                                        <button 
                                            className={preBtnColor} 
                                            disabled={preIsDisabled} 
                                            data-bs-toggle={toggle} 
                                            data-bs-target={target}
                                            onClick={onPremiumClick}
                                        >
                                            {
                                                userSubscriptionData.subscriptionType === "Freemium" ? (
                                                    <>Upgrade Now</>
                                                ): userSubscriptionData.subscriptionType === "Premium" && new Date(formattedToday) >= nextBillingDate() ? (
                                                    <>Renew Subscription</>
                                                ):(
                                                    <>Subscribe</>
                                                )
                                            }
                                                
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-2"></div>
                    </div>
                </div>
            </div>
            <div className="how-to py-4">
                <div className="container">
                    <h1 className='text-center pb-4' >How do I create a free QR Code?</h1>
                    <div className="row align-items-center">
                        <div className="col-sm-6 how-to-list">
                            <ol>
                                <li><p>Sign up or Sign in to get started.</p></li>
                                <li><p>Select from the navigation what you would like to generate the QR Code for.</p></li>
                                <li><p>Enter the relevant information or data.</p></li>
                                <li><p>Customize the appearance (optional).</p></li>
                                <li><p>Click "Generate" to create your unique QR code.</p></li>
                                <li><p>Download and share your QR code with ease!</p></li>
                            </ol>
                        </div>
                        <div className="col-sm-6 Qr-gif">
                            <img src={QRgif} alt="QR Gif"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact py-4" id="contact" >
                <div className="container">
                    <h2 className="text-center pb-4" >Get in Touch</h2>
                    <p className="text-center" >Feel free to contact me directly via Phone, WhatsApp, or Email, or fill up the form below to get in touch with me.</p>
                    <div className="contact-form">
                        <div className="row">
                        <div className="col-sm-4">
                            <div className="mb-3">
                            <h6  ><Phone/> <span><a href="tel:+233247154259"> +233 24 715 4259</a></span> </h6>
                            </div>
                            <div className="mb-3">
                            <h6  ><WhatsApp /><span><a href="https://wa.me/0247154259" target="_blank" rel="noreferrer"> +233 24 715 4259</a></span> </h6>
                            </div>
                            <div className="mb-3">
                            <h6  ><Email/> <span><a href="mailto:christiankpegah@gmail.com"> christiankpegah@gmail.com</a></span> </h6>
                            </div>
                            <div className="shaking-hands" >
                            <img src={shaking} className="img-fluid" alt="" />
                            </div>
                        </div>
                        <div className="col-sm-8">
                            <form onSubmit={(e) => sendEmail(userName, userEmail, userPhone, userMessage, e)} >
                                <div className="row">
                                    <div className="col-sm mt-2">
                                    <input type="text" value={userName} onChange={(e) => setUserName(e.target.value)} className="form-control" placeholder="Your Name" required />
                                    </div>
                                    <div className="col-sm mt-2">
                                    <input type="number" value={userPhone} onChange={(e) => setUserPhone(e.target.value)} className="form-control" placeholder="Your Phone Number" required />
                                    </div>
                                </div>
                                <input type="email" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} className="form-control mt-2" placeholder="Your Email" required />
                                <textarea value={userMessage}  onChange={(e) => setUserMessage(e.target.value)} cols="30" rows="10" className="form-control mt-2" placeholder="Your Message" required></textarea>
                            
                                <button className="btn btn-dark mt-2 w-100" type="submit"  >Send Message</button>
                            </form>
                            <div className="socials text-center mt-2">
                            <a href="https://www.linkedin.com/in/christian-kpegah-491461165/" target="_blank" rel="noreferrer" ><LinkedIn className="social-icon" /></a>
                            <a href="https://github.com/KurtKhris" target="_blank" rel="noreferrer" ><GitHub className="social-icon" /></a>
                            <a href="https://twitter.com/kurt_khris" target="_blank" rel="noreferrer" ><Twitter className="social-icon" /></a>
                            <a href="https://instagram.com/kurtkhris" target="_blank" rel="noreferrer" ><Instagram className="social-icon" /></a>
                            
                        </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Modal  */}

        <div className="modal fade" id="payment" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            <div className="modal-header">
                <h1 className="modal-title fs-5" id="staticBackdropLabel">Order Summary</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
            </div>
            <div className="modal-body">
                <div className="summary">
                    <div className='d-flex align-items-center justify-content-between mb-4' >
                        <h2 className='fw-bold text-success'>GH₵{finalAmount/100}</h2>
                        <h2 className='fw-bold' >{duration} month(s)</h2>
                    </div>
                    <div>
                        <div className='subsName' >
                            <div className='d-flex align-items-center mb-4'>
                                <img src={subscriptionLogo} width={50} height={50} alt="" />
                                <h6 className='ms-2'>{subscription} Monthly Plan</h6>
                            </div>
                            <hr />
                            <div>
                                <h6>Increase number of months</h6>
                                <div className='text-end' >
                                    
                                </div>
                                <div className='d-flex align-items-center justify-content-between mb-4' >
                                    <button className='btn btn-primary' onClick={decreaseSize} disabled={(finalAmount/100) === 50} ><RemoveCircle  color='#ffffff' /></button>
                                    <button className='btn btn-primary' onClick={increaseSize} ><AddCircle  color='#ffffff' /></button>
                                </div>
                            </div>
                            <hr />
                            <div className='d-flex align-items-center justify-content-between mb-3' >
                                <h6>Due today</h6>
                                <h6 className='fw-bold' >GH₵{finalAmount/100}</h6>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
            <div className="modal-footer">
                <PaystackButton className="btn btn-primary"  {...componentProps} />
                <button 
                    className={isDisabledColor} 
                    disabled={IsDisabled} 
                    data-bs-dismiss="modal" 
                    aria-label="Close"
                    onClick={() => {navigate('/dashboard')}} 
                >
                    Complete Purchase
                </button>
            </div>
            </div>
        </div>
        </div>

        <div className="whatsapp">
            <FloatingWhatsApp 
                phoneNumber="+233247154259" 
                accountName="Christian" 
                avatar={pic}
                statusMessage="Available 24/7"
                allowEsc
                allowClickAway
                notification
                notificationSound={true}
                darkMode={true}
            />
        </div>
        <Footer/>
    </div>
  )
}
