import React, {useState, useContext, useEffect} from 'react';
import logo from '../assets/logo.png';
import avatar from '../assets/avatar.png';
import { Backdrop, CircularProgress, IconButton, Alert, Snackbar, Tooltip, Menu, MenuItem, ListItemIcon,} from '@mui/material';
import { Link, FileOpen, Home, LogoutOutlined, AccountCircleOutlined, DashboardOutlined, TextSnippetOutlined, Upgrade, Refresh, Logout, NetworkWifiTwoTone, Wallet, Share, ContactPageOutlined } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { SharedFunctions } from '../shared/Functions';
import Url from '../screens/Url';
import Files from '../screens/Files';
import Profile from '../screens/Profile';
import MenuIcon from '@mui/icons-material/Menu';
import MyStuff from '../screens/MyStuff';
import Text from '../screens/Text';
import Wifi from '../screens/Wifi';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import pic from '../assets/self2.png'
import { useNavigate } from 'react-router-dom';
import MTNMoMoPay from '../screens/MtnMoMoPay';
import SocialMedia from '../screens/SocialMedia';
import EmailPhone from '../screens/EmailPhone';

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: 999, 
      color: '#000',
    },
  }));

  

export default function DashNav() {
    const {currentUser, isLoading, upGradeFreemium, open, handleClose, handleSignOut, userSubscriptionData, nextBillingDate, subscriptionExpiry, userInfo, sendEmails, formatName} = useContext(SharedFunctions)
    const [screen, setScreen] = useState('MyStuff');
    const classes = useStyles();
    const year = new Date().getFullYear();
    const [anchorEl, setAnchorEl] = useState(null);
    const open2 = Boolean(anchorEl);
    const navigate = useNavigate();
    const today = new Date();
    const formattedToday = today.toLocaleDateString();

    useEffect(() => {
       if (sessionStorage.getItem("token") === null){
            navigate("/signin/email")
       }
        // eslint-disable-next-line
      }, []);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose2 = () => {
        setAnchorEl(null);
    };

    const image1 = "https://i.imgur.com/34StdsQ.png";
    const title1 = "Upgrade Subscription";
    const subject1 =  "Upgrade Subscription";
    const msg1 = "You have exceeded the number of QR codes you can generate under the Freemium Subscription. Upgrade to Premium to continue using our services. https://qrgenn.techieszon.com/#pricing."

    const image2 = "https://i.imgur.com/eMDV6Rz.png";
    const title2 = "Subscription Expired";
    const subject2 =  "Subscription Expired";
    const msg2 = "Your Subscription has expired. Renew now to continue enjoying our services. https://qrgenn.techieszon.com/#pricing."


    const renderScreen = () =>{
        switch(screen){
            case 'Url':
                return <Url/>;
            case 'Text':
                return <Text/>;
            case 'Files':
                return <Files/>;
            case 'Profile':
                return <Profile/>;
            case 'Wifi':
                return <Wifi/>;
            case 'MTN':
                    return <MTNMoMoPay/>;
            case 'Social':
                    return <SocialMedia/>;
            case 'Contact':
                return <EmailPhone/>;
            case 'MyStuff':
                return <MyStuff/>
            default:
                return <MyStuff />;
        }
    }

    const handleDashboardClick = () => {
        setScreen('MyStuff');
        upGradeFreemium();
        subscriptionExpiry();
    }

    const handleProfileClick = () => {
        setScreen('Profile');
        upGradeFreemium();
        subscriptionExpiry();
    }

    const handleUrlClick = () => {
        setScreen('Url');
        upGradeFreemium();
        subscriptionExpiry();
    }

    const handleTextClick = () => {
        setScreen('Text');
        upGradeFreemium();
        subscriptionExpiry();
    }

    const handleFileClick = () => {
        setScreen('Files');
        upGradeFreemium();
        subscriptionExpiry();
    }

    const handleWifiClick = () => {
        setScreen('Wifi');
        upGradeFreemium();
        subscriptionExpiry();
    }

    const handleMtnMoMoClick = () => {
        setScreen('MTN');
        upGradeFreemium();
        subscriptionExpiry();
    }

    const handleSocialMediaClick = () => {
        setScreen('Social');
        upGradeFreemium();
        subscriptionExpiry();
    }

    const handleEmailPhoneClick = () => {
        setScreen('Contact');
        upGradeFreemium();
        subscriptionExpiry();
    }

    useEffect(() => {
        if (
            sessionStorage.getItem('tableData') === '2' &&
            sessionStorage.getItem('subscriptionType') === 'Freemium'
          ) {
            sendEmails(userInfo.email, formatName(userInfo.name), image1, title1, subject1, msg1);
          } else if (
            userSubscriptionData.subscriptionExpiry === 'Premium' &&
            new Date(formattedToday) >= nextBillingDate()
          ) {
            sendEmails(userInfo.email, formatName(userInfo.name), image2, title2, subject2, msg2);
          }
        // eslint-disable-next-line
      }, []);



  return (
    <div>
    <nav className="navbar navbar-expand-lg bg shadow sticky-top">
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        <div className="container">
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample"
            >
                <MenuIcon sx={{color: 'white', fontSize: 40}}  />
            </IconButton>
            <a className="navbar-brand text-white d-flex align-items-center" href="/">
                <img className='logo' src={logo} alt='logo' />
                <div className='ms-3 name' >
                    <h2 className='mb-0 fw-bold' >QR GenN</h2>
                    <p className='mb-0' >Scan, Share, and Connect!!</p>
                </div>
            </a>
            <ul className="navbar-nav me-auto mb-2 mb-lg-0  ">
                
            </ul>
            <div className="d-flex d-none d-lg-block">
            <Tooltip title="Account settings">
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open2 ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open2 ? 'true' : undefined}
                >
                    <div >
                        <img className='rounded-circle' src={(currentUser && currentUser.photoURL) || avatar } alt='user' width={40}  />
                        
                    </div>
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open2}
                onClose={handleClose2}
                onClick={handleClose2}
                PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                    },
                    '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                    },
                },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleProfileClick} >
                <ListItemIcon>
                    <AccountCircleOutlined fontSize="small" />
                </ListItemIcon>  
                Profile
                </MenuItem>
                <hr style={{color: 'black'}}></hr>
                <MenuItem onClick={handleSignOut}>
                <ListItemIcon>
                    <Logout fontSize="small" />
                </ListItemIcon>
                    Sign Out
                </MenuItem>
            </Menu>
            </div>
        </div>
    </nav>

    <div className="offcanvas offcanvas-start bg" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
    <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            <div className='ms-3' >
                <img className='rounded-circle' src={(currentUser && currentUser.photoURL) || avatar} alt='user' width={40}  />
            </div>
        </h5>
        <button type="button" className="btn-close bg-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <hr className='mb-0 mt-2' style={{color: 'white'}}></hr>
    <div className="offcanvas-body">
        <nav className='nav flex-column' >
            <a className="nav-link sideNavItems dashNavItems" href="/"><Home className='me-3'/> Home</a>  
            <p className="nav-link sideNavItems dashNavItems mb-0" data-bs-dismiss="offcanvas" onClick={handleDashboardClick} ><DashboardOutlined className='me-3' /> Dashboard</p>
            <p className="nav-link sideNavItems dashNavItems mb-0" data-bs-dismiss="offcanvas" onClick={handleProfileClick} ><AccountCircleOutlined className='me-3'/> Profile</p>
            <p className="nav-link sideNavItems dashNavItems mb-0" data-bs-dismiss="offcanvas" onClick={handleTextClick} ><TextSnippetOutlined className='me-3'/> Text</p>
            <p className="nav-link sideNavItems dashNavItems mb-0" data-bs-dismiss="offcanvas" onClick={handleUrlClick} ><Link className='me-3'/> Url</p>
            <p className="nav-link sideNavItems dashNavItems mb-0" data-bs-dismiss="offcanvas" onClick={handleSocialMediaClick} ><Share className='me-3'/> Social Media</p>
            <p className="nav-link sideNavItems dashNavItems mb-0" data-bs-dismiss="offcanvas" onClick={handleEmailPhoneClick} ><ContactPageOutlined className='me-3'/> Email & Phone</p>
            <p className="nav-link sideNavItems dashNavItems mb-0" data-bs-dismiss="offcanvas" onClick={handleFileClick} ><FileOpen className='me-3'/> Files</p>
            <p className="nav-link sideNavItems dashNavItems mb-0" data-bs-dismiss="offcanvas" onClick={handleWifiClick} ><NetworkWifiTwoTone className='me-3'/> Wi-Fi Networks</p>
            <p className="nav-link sideNavItems dashNavItems mb-0" data-bs-dismiss="offcanvas" onClick={handleMtnMoMoClick} ><Wallet className='me-3'/> MTN MoMo Pay</p>
            <hr style={{color: 'white'}}></hr>
            <p className="nav-link sideNavItems dashNavItems" onClick={handleSignOut} ><LogoutOutlined className='me-3'/>Logout</p>
        </nav>
        <div className='position-absolute bottom-0 start-0'>
            <hr style={{color: 'white'}}></hr>
            <h6 className=" text-white text-center">&copy; {year} QR GenN - All Rights Reserved</h6>
        </div>
    </div>
    </div>
        <div className=' container spacer' >
            <Snackbar 
                open={open} 
                onClose={handleClose} 
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
                autoHideDuration={null}
                style={{ top: '50%'}}
                >
                <Alert onClose={handleClose} severity='error' variant='felled' sx={{width: '100%', backgroundColor: 'red', color: '#ffffff'}} >
                {sessionStorage.getItem("tableData") === "2" && sessionStorage.getItem("subscriptionType") === "Freemium" ? (
                    <>
                        You have exceeded the number of QR codes you can generate under the Freemium Subscription. Upgrade to premium to continue using our services. Thank you.
                        <br/>
                        <div className='text-center mt-3' >
                            <a href='./#pricing' className="btn btn-success mb-3">
                                <Upgrade/> Upgrade Now
                            </a>
                            <a href='/' className="btn btn-primary ms-3 mb-3">
                                Return to Home
                            </a>
                        </div>
                    </>
                ) : sessionStorage.getItem("subscriptionType") === "Freemium" ? (
                    <>
                        You cannot delete while in the Freemium Subscription 
                        <br/>
                        <div className='text-center mt-3' >
                            <a href='./#pricing' className="btn btn-success mb-3">
                                <Upgrade/> Upgrade Now
                            </a>
                            <a href='/' className="btn btn-primary ms-3 mb-3">
                                Return to Home
                            </a>
                        </div>
                    </>
                ) : userSubscriptionData.subscriptionType=== "Premium" && new Date(formattedToday) >= nextBillingDate() ? (
                    <>
                        Your Subscription has expired. Renew now to continue enjoying our services
                        <br/>
                        <div className='text-center mt-3' >
                            <a href='./#pricing' className="btn btn-success mb-3">
                                <Refresh/> Renew Now
                            </a>
                            <a href='/' className="btn btn-primary ms-3 mb-3">
                                Return to Home
                            </a>
                        </div>
                    </>
                ):null}
                </Alert>
            </Snackbar>
            <Backdrop
                className={classes.backdrop}
                open={open}
            ></Backdrop>
            {renderScreen()}
            <div className="whatsapp">
            <FloatingWhatsApp 
                phoneNumber="+233247154259" 
                accountName="Christian" 
                avatar={pic}
                statusMessage="Available 24/7"
                allowEsc
                allowClickAway
                notification
                notificationSound={true}
                darkMode={true}
            />
        </div>
        </div>
    </div>
  )
}


