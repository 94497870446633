import React, {useState, useContext, useEffect} from 'react';
import { TextField, Select, MenuItem, InputLabel, FormControl, FormLabel} from '@mui/material';
import { QRCodeCanvas } from 'qrcode.react';
import {v4} from "uuid";
import { writeData } from '../firebase';
import { Email,Phone, Save} from '@mui/icons-material';
import { SharedFunctions } from '../shared/Functions';
import { Brush, ColorLens, AddCircle, RemoveCircle } from '@mui/icons-material';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import phoneLogo from '../assets/phone.png';
import emailLogo from '../assets/email.png';

function EmailPhone() {
    const {user_id, upGradeFreemium, userSubscriptionData, subscriptionExpiry, nextBillingDate} = useContext(SharedFunctions);
    const id = v4();
    const today = new Date();
    const formattedToday = today.toLocaleDateString();
    const [emailPhoneLink, setEmailPhoneLink] = useState('');
    const [emailPhone, setEmailPhone] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');

    const [qrSize, setQrSize] = useState(parseInt(288));
    const [bgColor, setBgColor] = useState('#ffffff');
    const [fgColor, setFgColor] = useState('#000000');
    const [logo, setLogo] = useState(null); 
    const [show, setShow] = useState("");

    const increaseSize = () => {
        const newSize = qrSize + 5;
        setQrSize(newSize);
      };

      const decreaseSize = () => {
        const newSize = Math.max(qrSize - 5, 288);
        setQrSize(newSize);
      };

      const handleColorChange = (e) => {
        setBgColor(e.target.value);
      };

      const handleFgColorChange = (e) => {
        setFgColor(e.target.value);
      };

      const toggleColorPicker = () => {
        setShow("Background");
      };

      const toggleColorPicker1 = () => {
        setShow("Foreground");
      };


    const handleSocialMediaChange = (event) => {
        const newSocial = event.target.value;
        if(sessionStorage.getItem("tableData") === "2" && sessionStorage.getItem("subscriptionType") === "Freemium"){
            upGradeFreemium()
        }else if(userSubscriptionData.subscriptionType === "Premium" && new Date(formattedToday) === nextBillingDate()){
            subscriptionExpiry();
        }else{
            setEmailPhone(newSocial);
        }
    };

    
    const handleSocialMediaLinkChange = () => {
        if (emailPhone === "Phone"){
            setEmailPhoneLink(`tel:${phone}`)
            setLogo(phoneLogo)
        }else if (emailPhone === "Email"){
            setEmailPhoneLink(`mailto:${email}`)
            setLogo(emailLogo)
        }
    }

    useEffect(() => {
        handleSocialMediaLinkChange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailPhone, phone, email]);

    const handleDownloadClick = () => {
        const qrCodeCanvas = document.getElementById('qrgen');
        const dataUrl = qrCodeCanvas.toDataURL('image/png');
        writeData(user_id, emailPhoneLink, dataUrl, id)
    };
  return (
    <div>
        <div className="py-3">
            <div className="row">
                <div className="col-sm-8">
                    <h6 className='mb-3'>Select Email or Phone.</h6>
                    <div className='mb-3'>
                        <FormControl fullWidth required >
                            <InputLabel id="demo-simple-select-helper-label">Email or Phone</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={emailPhone}
                                label="Email or Phone"
                                onChange={handleSocialMediaChange}
                                required
                            >
                            <MenuItem value="Phone"><Phone />Phone Number</MenuItem>
                            <MenuItem value="Email"><Email />Email Address</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <form>
                    {emailPhone === 'Phone' && (
                        <div className="mb-3">
                            <FormLabel>Phone Number</FormLabel>
                            <PhoneInput placeholder='024 123 4567' defaultCountry='GH' className='phone-input' limitMaxLength={true} value={phone} onChange={setPhone} required />
                        </div>
                    )}
                    {emailPhone === 'Email' && (
                        <TextField 
                            label='Email Address'
                            type='email'
                            placeholder='Email Address'
                            variant='outlined'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                            className='mb-3'
                            required
                        />
                    )}
                    </form>
                    
                    { (phone || email) && (
                    <div className="customization d-none d-lg-block mt-4">
                        <h3>Customization Panel</h3>
                        <div className='d-flex flex-column flex-lg-row justify-content-between' >
                            <button className='btn btn-primary mb-3' onClick={increaseSize} >Size <AddCircle/> </button>
                            <button className='btn btn-primary mb-3' onClick={decreaseSize} >Size <RemoveCircle/> </button>
                            <button className='btn btn-primary mb-3' onClick={toggleColorPicker}>Background Color <Brush/> </button>
                            <button className='btn btn-primary mb-3' onClick={toggleColorPicker1} >Foreground Color <ColorLens/> </button>
                        </div>
                        {show === "Background" && (
                            <div className='mb-3' >
                                <label className='form-label'>Backgound Color Picker</label>
                                <input type="color" className='form-control' value={bgColor} onChange={handleColorChange} />
                            </div>
                        )}
                        {show === "Foreground" && (
                            <div className='mb-3' >
                                <label className='form-label'>Foreground Color Picker</label>
                                <input type="color" className='form-control' value={fgColor} onChange={handleFgColorChange} />
                            </div>
                        )}
                    </div>
                     )}
                </div>
                <div className="col-sm-4">
                    { (phone || email) && (
                        <>
                        <h4 className='text-center' >QR Code</h4>
                        <div className='text-center mb-3'>
                            <QRCodeCanvas imageSettings={{src: logo, width: 40, height: 40, excavate: true}} bgColor={bgColor} fgColor={fgColor} size={qrSize} id='qrgen' value={emailPhoneLink}  />
                        </div>

                        <div className='text-center'>
                            <button className='btn btn-primary' onClick={handleDownloadClick} >
                                <Save/>
                             Save
                            </button>
                        </div>
                            
                        </>
                    )}
                </div>
            </div>
            { (phone || email) && (
            <div className="customization d-lg-none mt-4">
                <h3>Customization Panel</h3>
                <div className='d-flex flex-column flex-lg-row justify-content-between' >
                    <button className='btn btn-primary mb-3' onClick={increaseSize} >Size <AddCircle/> </button>
                    <button className='btn btn-primary mb-3' onClick={decreaseSize} >Size <RemoveCircle/> </button>
                    <button className='btn btn-primary mb-3' onClick={toggleColorPicker}>Background Color <Brush/> </button>
                    <button className='btn btn-primary mb-3' onClick={toggleColorPicker1} >Foreground Color <ColorLens/> </button>
                </div>
                {show === "Background" && (
                    <div className='mb-3' >
                        <label className='form-label'>Backgound Color Picker</label>
                        <input type="color" className='form-control' value={bgColor} onChange={handleColorChange} />
                    </div>
                )}
                {show === "Foreground" && (
                    <div className='mb-3' >
                        <label className='form-label'>Foreground Color Picker</label>
                        <input type="color" className='form-control' value={fgColor} onChange={handleFgColorChange} />
                    </div>
                )}
            </div>
            )}
        </div>
    </div>
  )
}

export default EmailPhone