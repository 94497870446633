import React from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

function PrivacyPolicy() {
  return (
    <div>
        <NavBar/>
        <div className="container mt-3">
            <h4>Privacy Policy for QR GenN</h4>

            <p>Last Updated: 23/04/2024</p>

            <p>Thank you for choosing QR GenN, a QR code generation system provided by Techieszon. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our website <a href="http://qrgenn.techieszon.com">qrgenn.techieszon.com</a>.</p>

            <h4>1. Information We Collect</h4>

            <p><strong>1.1 Personal Information:</strong> We may collect personal information that you provide voluntarily when you use our Service. This may include your name, email address, and any other information you choose to provide.</p>

            <p><strong>1.2 Usage Data:</strong> We may also collect information automatically when you use the Service. This may include your IP address, browser type, operating system, referral URLs, device information, pages visited, and other usage data.</p>

            <h4>2. Use of Information</h4>

            <p><strong>2.1 Provide and Maintain the Service:</strong> We use the information we collect to provide and maintain the Service, including generating QR codes and fulfilling your requests.</p>

            <p><strong>2.2 Improve the Service:</strong> We may use your information to improve the Service, analyze trends, monitor usage, and gather demographic information.</p>

            <p><strong>2.3 Communication:</strong> We may use your email address to communicate with you about the Service, such as sending updates, newsletters, or notifications about your account.</p>

            <h4>3. Disclosure of Information</h4>

            <p>We may disclose your information in the following circumstances:</p>

            <ul>
                <li><strong>With Your Consent:</strong> We may share your information with your consent or at your direction.</li>
                <li><strong>Service Providers:</strong> We may share your information with third-party service providers who assist us in operating the Service, conducting our business, or servicing you.</li>
                <li><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or government agency).</li>
            </ul>

            <h4>4. Security</h4>

            <p>We take reasonable measures to protect the security of your information. However, no method of transmission over the Internet or electronic storage is 100% secure. Therefore, while we strive to use commercially acceptable means to protect your information, we cannot guarantee its absolute security.</p>

            <h4>5. Links to Other Sites</h4>

            <p>Our Service may contain links to other websites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>

            <h4>6. Children's Privacy</h4>

            <p>Our Service is not intended for use by children under the age of 13. We do not knowingly collect personally identifiable information from children under 13. If you become aware that a child has provided us with personal information, please contact us, and we will take steps to remove such information from our systems.</p>

            <h4>7. Changes to This Privacy Policy</h4>

            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>

            <h4>8. Contact Us</h4>

            <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:qrgenn@gmail.com">qrgenn@gmail.com</a>.</p>

            <p>By using the Service, you signify your acceptance of this Privacy Policy. If you do not agree to this Privacy Policy, please do not use the Service.</p>

        </div>
        <Footer/>
    </div>
  )
}

export default PrivacyPolicy