import React, {useContext} from 'react';
import { Box, Backdrop, CircularProgress } from '@mui/material';
import { SharedFunctions } from '../shared/Functions';

export default function Profile() {
    const {isLoading, currentUser, nextBillingDate, userInfo, userSubscriptionData, formatName} = useContext(SharedFunctions)
    
  return (
    <div >
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="80vh"
        >
            <h4 className='mb-3'>User Details</h4>
            <div className='d-flex justify-content-between shadow p-3 mb-3' >
                <div className='label'>
                    <h5>Name</h5>
                    <h5>Email</h5>
                    <h5>Phone Number</h5>
                    <h5>Subscription</h5>
                    {sessionStorage.getItem("subscriptionType") === "Premium" && (<h5>Next Billing Date</h5>)}
                    {sessionStorage.getItem("subscriptionType") === "Premium" && (<h5>Subscription Duration</h5>)}
                    
                </div>
                <div className='data text-end ms-5' >
                    <h5> {currentUser && (currentUser.displayName || formatName(userInfo.name))} </h5>
                    <h5> {currentUser && (currentUser.email || userInfo.email)} </h5>
                    <h5> {currentUser && (currentUser.phoneNumber || userInfo.phone)} </h5>
                    <h5> {sessionStorage.getItem("subscriptionType")} </h5>
                    {sessionStorage.getItem("subscriptionType") === "Premium" && (<h5> {nextBillingDate()} </h5>)}
                    {sessionStorage.getItem("subscriptionType") === "Premium" && (<h5> {userSubscriptionData.numOfMonths} month(s) </h5>)}
                    
                </div>
            </div>
        </Box>
    </div>
  )
}
