import React from 'react';
import Text from './Text';

export default function Url() {
  return (
    <div>
        <Text/>
    </div>
  )
}
