import React from 'react';
import { Box } from '@mui/material';
import { auth, googleProvider, facebookProvider, gitHubProvider, twitterProvider } from '../firebase';
import {signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';

export default function SignUp() {
    const navigate = useNavigate();

    const handleGoogleClick = async () => {
        try {
            await signInWithPopup(auth,googleProvider)
            navigate('/dashboard')
        } catch (error) {
            console.log(error)
        }
    }

    const handleFacebookClick = async () => {
        try {
            await signInWithPopup(auth,facebookProvider)
            
            navigate('/dashboard')
        } catch (error) {
            console.log(error)
        }
    }

    const handleGitHubClick = async () => {
        try {
            await signInWithPopup(auth,gitHubProvider)
            
            navigate('/dashboard')
        } catch (error) {
            console.log(error)
        }
    }

    const handleTwitterClick = async () => {
        try {
            await signInWithPopup(auth,twitterProvider)
            
            navigate('/dashboard')
        } catch (error) {
            console.log(error)
        }
    }


  return (
    <div className='container-fluid sign-in-bg' >
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="100vh"
        >
            <img className='mb-4' src={logo} alt="logo" width={80} height={80} />
            <h3 className='text-white mb-4' >Sign up</h3>
            <div className='text-center'>
                <div className='social-signin d-grid gap-4 mb-3' >
                    <button className='btn btn-outline-light text-start' onClick={handleGoogleClick}><img width="40" height="40" src="https://img.icons8.com/color/40/google-logo.png" alt="google-logo"/> Sign up with Google</button>
                    <button className='btn btn-outline-light text-start' onClick={handleFacebookClick}><img width="40" height="40" src="https://img.icons8.com/ios-filled/40/2BBFBD/facebook-new.png" alt="facebook-new"/> Sign up with Facebook</button>
                    <button className='btn btn-outline-light text-start' onClick={handleGitHubClick}><img width="40" height="40" src="https://img.icons8.com/material-outlined/40/2BBFBD/github.png" alt="github"/> Sign up with GitHub</button>
                    <button className='btn btn-outline-light text-start' onClick={handleTwitterClick}><img width="40" height="40" src="https://img.icons8.com/ios-filled/2BBFBD/40/twitterx--v1.png" alt="twitterx--v1"/> Sign up with Twitter</button>
                </div>
                <div className='sign-email mb-5'>
                    <a href="/signup/email">Sign up with email</a>
                </div>
                <div className='sign-email mb-5'>
                    <span className='text-white' >Already have an account? </span><a href="/signin">Sign in</a>
                </div>
            </div>
        </Box>
    </div>
  )
}
