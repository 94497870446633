import React, {useState, useContext} from 'react';
import { TextField, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { QRCodeCanvas } from 'qrcode.react';
import {v4} from "uuid";
import { writeData } from '../firebase';
import { Save } from '@mui/icons-material';
import { SharedFunctions } from '../shared/Functions';
import { Brush, ColorLens, Image, AddCircle, RemoveCircle } from '@mui/icons-material';
import ToastService from "react-material-toast";

const toast = ToastService.new({
    place: "topRight",
    duration: 1,
    maxCount: 1,
  });

  export default function Wifi() {
    const {user_id, upGradeFreemium, userSubscriptionData, subscriptionExpiry, nextBillingDate} = useContext(SharedFunctions);
    const [wifiSSID, setSSID] = useState('');
    const [wifiPassword, setWifiPassword] = useState('');
    const [wifiSecurity, setWifiSecurity] = useState('');
    const id = v4();

    const [qrSize, setQrSize] = useState(parseInt(288));
    const [bgColor, setBgColor] = useState('#ffffff');
    const [fgColor, setFgColor] = useState('#000000');
    const [logo, setLogo] = useState(null); 
    const [show, setShow] = useState("");
    const [showDisplay, setShowDisplay] = useState(false);
    const today = new Date();
    const formattedToday = today.toLocaleDateString();

    const increaseSize = () => {
        const newSize = qrSize + 5;
        setQrSize(newSize);
      };

      const decreaseSize = () => {
        const newSize = Math.max(qrSize - 5, 288);
        setQrSize(newSize);
      };

      const handleColorChange = (e) => {
        setBgColor(e.target.value);
      };

      const handleFgColorChange = (e) => {
        setFgColor(e.target.value);
      };

      const toggleColorPicker = () => {
        setShow("Background");
      };

      const toggleColorPicker1 = () => {
        setShow("Foreground");
      };

      const toggleColorPicker2 = () => {
        setShow("Image");
      };

    const handleLogoChange = (e) => {
        const file = e.target.files[0];
      
        if (file) {
          const maxSizeInBytes = 1024 * 1024;
          if (file.size > maxSizeInBytes) {
            toast.error('File size exceeds the limit. Please choose a smaller file.');
            return;
          }
      
          const reader = new FileReader();
          reader.onloadend = () => {
            setLogo(reader.result);
          };
          reader.readAsDataURL(file);
        }
      };
      
    

    const handleSSIDChange = (event) => {
        const newSSID = event.target.value;
        if(sessionStorage.getItem("tableData") === "2" && sessionStorage.getItem("subscriptionType") === "Freemium"){
            upGradeFreemium()
        }else if(userSubscriptionData.subscriptionType === "Premium" && new Date(formattedToday) >= nextBillingDate()){
            subscriptionExpiry();
        }else{
            setSSID(newSSID);
        }
    };

    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;
        if(sessionStorage.getItem("tableData") === "2" && sessionStorage.getItem("subscriptionType") === "Freemium"){
            upGradeFreemium()
        }else if(userSubscriptionData.subscriptionType === "Premium" && new Date(formattedToday) >= nextBillingDate()){
            subscriptionExpiry();
        }else{
            setWifiPassword(newPassword);
        }
    };

    const handleSecurityChange = (event) => {
        const newSecurity = event.target.value;
        if(sessionStorage.getItem("tableData") === "2" && sessionStorage.getItem("subscriptionType") === "Freemium"){
            upGradeFreemium()
        }else if(userSubscriptionData.subscriptionType === "Premium" && new Date(formattedToday) === nextBillingDate()){
            subscriptionExpiry();
        }else{
            setWifiSecurity(newSecurity);
            setShowDisplay(true);
        }
    };

    const wifiURI = `WIFI:S:${wifiSSID};T:${wifiSecurity};P:${wifiPassword};;`;

    const handleDownloadClick = () => {
        const qrCodeCanvas = document.getElementById('qrgen');
        const dataUrl = qrCodeCanvas.toDataURL('image/png');
        writeData(user_id, wifiURI, dataUrl, id)
    };


  return (
    <div>
        <div className="py-3">
            <div className="row">
                <div className="col-sm-8">
                    <h6 className='mb-3'>Enter Wi-Fi network details below. Once you are done, your QR Code will be generated.</h6>
                    <form>
                        <TextField 
                            label='Wi-Fi SSID'
                            placeholder='Wi-Fi SSID'
                            variant='outlined'
                            fullWidth
                            className='mb-3'
                            value={wifiSSID}
                            onChange={handleSSIDChange}
                            required
                        />
                        <TextField 
                            label='Wi-Fi Password'
                            placeholder='Wi-Fi Password'
                            variant='outlined'
                            fullWidth
                            className='mb-3'
                            value={wifiPassword}
                            onChange={handlePasswordChange}
                            required
                        />
                        <div className='mb-3'>
                            <FormControl fullWidth required >
                                <InputLabel id="demo-simple-select-helper-label">Wi-Fi Security</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={wifiSecurity}
                                    label="Wi-Fi Security"
                                    onChange={handleSecurityChange}
                                    required
                                >
                                <MenuItem value="WEP">WEP</MenuItem>
                                <MenuItem value="WPA">WPA</MenuItem>
                                <MenuItem value="WPA2">WPA2</MenuItem>
                                <MenuItem value="WPA3">WPA3</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </form>
                    
                    {showDisplay && (
                    <div className="customization d-none d-lg-block mt-4">
                        <h3>Customization Panel</h3>
                        <div className='d-flex flex-column flex-lg-row justify-content-between' >
                            <button className='btn btn-primary mb-3' onClick={increaseSize} >Size <AddCircle/> </button>
                            <button className='btn btn-primary mb-3' onClick={decreaseSize} >Size <RemoveCircle/> </button>
                            <button className='btn btn-primary mb-3' onClick={toggleColorPicker}>Background Color <Brush/> </button>
                            <button className='btn btn-primary mb-3' onClick={toggleColorPicker1} >Foreground Color <ColorLens/> </button>
                            <button className='btn btn-primary mb-3' onClick={toggleColorPicker2} >Insert Logo <Image/> </button>
                        </div>
                        {show === "Background" && (
                            <div className='mb-3' >
                                <label className='form-label'>Backgound Color Picker</label>
                                <input type="color" className='form-control' value={bgColor} onChange={handleColorChange} />
                            </div>
                        )}
                        {show === "Foreground" && (
                            <div className='mb-3' >
                                <label className='form-label'>Foreground Color Picker</label>
                                <input type="color" className='form-control' value={fgColor} onChange={handleFgColorChange} />
                            </div>
                        )}
                        {show === "Image" && (
                            <div className='mb-3' >
                                <label className='form-label'>Upload Icon</label>
                                <input className='form-control' type="file" accept="image/*" onChange={handleLogoChange} />
                                <h6>Icon size should not be more than 1024kb or 1mb</h6>
                            </div>
                        )}
                    </div>
                     )}
                </div>
                <div className="col-sm-4">
                    {showDisplay && (
                        <>
                        <h4 className='text-center' >QR Code</h4>
                        <div className='text-center mb-3'>
                            <QRCodeCanvas imageSettings={{src: logo, width: 40, height: 40, excavate: true}} bgColor={bgColor} fgColor={fgColor} size={qrSize} id='qrgen' value={wifiURI}  />
                        </div>

                        <div className='text-center'>
                            <button className='btn btn-primary' onClick={handleDownloadClick} >
                                <Save/>
                             Save
                            </button>
                        </div>
                            
                        </>
                    )}
                </div>
            </div>
            {showDisplay && (
            <div className="customization d-lg-none mt-4">
                <h3>Customization Panel</h3>
                <div className='d-flex flex-column flex-lg-row justify-content-between' >
                    <button className='btn btn-primary mb-3' onClick={increaseSize} >Size <AddCircle/> </button>
                    <button className='btn btn-primary mb-3' onClick={decreaseSize} >Size <RemoveCircle/> </button>
                    <button className='btn btn-primary mb-3' onClick={toggleColorPicker}>Background Color <Brush/> </button>
                    <button className='btn btn-primary mb-3' onClick={toggleColorPicker1} >Foreground Color <ColorLens/> </button>
                    <button className='btn btn-primary mb-3' onClick={toggleColorPicker2} >Insert Logo <Image/> </button>
                </div>
                {show === "Background" && (
                    <div className='mb-3' >
                        <label className='form-label'>Backgound Color Picker</label>
                        <input type="color" className='form-control' value={bgColor} onChange={handleColorChange} />
                    </div>
                )}
                {show === "Foreground" && (
                    <div className='mb-3' >
                        <label className='form-label'>Foreground Color Picker</label>
                        <input type="color" className='form-control' value={fgColor} onChange={handleFgColorChange} />
                    </div>
                )}
                {show === "Image" && (
                    <div className='mb-3' >
                        <label className='form-label'>Upload Icon</label>
                        <input className='form-control' type="file" accept="image/*" onChange={handleLogoChange} />
                        <h6>Icon size should not be more than 1024kb or 1mb</h6>
                    </div>
                )}
            </div>
            )}
        </div>
    </div>
  )
}
