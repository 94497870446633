import React from 'react'

export default function Footer() {
    const year = new Date().getFullYear();
  return (
    <div className='footer bg shadow py-3 mb-0' >
        <h6 className=" text-white text-center">&copy; {year} QR GenN - All Rights Reserved</h6>
        <h6 className=" text-white text-center"><a className='text-white' href='/privacy-policy' >Privacy Policy</a></h6>
    </div>
  )
}
