import React, { useState, useContext, useEffect } from 'react';
import { TextField, Box, Backdrop, CircularProgress, InputAdornment, IconButton, FormLabel, Dialog, DialogContent, useMediaQuery } from '@mui/material';
import { auth, writeSubscription, writeUserData } from '../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import ToastService from "react-material-toast";
import { useNavigate } from 'react-router-dom';
import { AddCircle, Email, Lock, Person, RemoveCircle, Visibility, VisibilityOff } from '@mui/icons-material';
import logo from '../assets/logo.png';
import { SharedFunctions } from '../shared/Functions';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { PaystackButton  } from 'react-paystack';
import { useTheme } from '@mui/material/styles';

const toast = ToastService.new({
    place: "topRight",
    duration: 1,
    maxCount: 1,
  });


export default function SignUpEmail() {
    const {sendEmails, premiumMonthlyPrice, duration, setDuration, finalAmount, setFinalAmount, subscription, subscriptionLogo, premiumCalc, publicKey, secretKey, formatName} = useContext(SharedFunctions)
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [phone_number, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading,  setIsLoading] = useState(false);
    const [isDisabledColor, setIsDisabledColor] = useState("btn btn-secondary");
    const [IsDisabled, setIsDisabled] = useState(true)
    const [open, setOpen] = useState(false);
    const [ref, setRef] = useState({});
    const [user_Uid, setUserUid] = useState("");

    const image = "https://i.imgur.com/jR7ieML.png";
    const title = "Registration Successful";
    const subject =  "Registration Successful";
    const msg = "Welcome to QR GenN.\nYour registration has been successful. You are currently on the Freemium Subscription plan. To enjoy the fullness of the system, upgrade your subscription to Premium. https://qrgenn.techieszon.com/#pricing"

    const image1 = "https://i.imgur.com/9SeQofR.png";
    const title1 = "Subscription Successful";
    const subject1 =  "Subscription Successful";
    const msg1 = `Your have successfully subscribed to the Premium monthly subscription plan. Enjoy unlimited creation of dynamic QR codes.`

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
          setOpen(false);
      };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const checkPasswordStrength = (password) => {
        /* eslint-disable */
        const lengthRegex = /.{8,}/;
        const uppercaseRegex = /[A-Z]/;
        const lowercaseRegex = /[a-z]/;
        const numberRegex = /\d/;
        const specialCharRegex = /[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/;
        const commonPasswordRegex = /^(?=.*[Pp][Aa][Ss][Ss])(?=.*[Ww][Oo][Rr][Dd])|^(?=.*[0-9])$/;
        
        // Check password against criteria
        if (!lengthRegex.test(password)) {
          return 'Password must be at least 8 characters long';
        } else if (!uppercaseRegex.test(password)) {
          return 'Password must contain at least one uppercase letter';
        } else if (!lowercaseRegex.test(password)) {
          return 'Password must contain at least one lowercase letter';
        } else if (!numberRegex.test(password)) {
          return 'Password must contain at least one number';
        } else if (!specialCharRegex.test(password)) {
          return 'Password must contain at least one special character';
        } else if (commonPasswordRegex.test(password)) {
          return 'Password is too common';
        } else {
          return 'Password is strong';
        }
    } 

    useEffect(() => {
        premiumCalc();     
        // eslint-disable-next-line
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        const subscriptionType= "Freemium";
        const amount = 0.00;
        const message = "Approved";
        const status = "success";
        const transaction = "";
        const trxref = "";
        const reference = "";
        const duration = "";
        
        if (checkPasswordStrength(password) !== "Password is strong"){
            toast.error("Password is not strong enough!")
        }else{
            setIsLoading(true)
            try {
                const res = await createUserWithEmailAndPassword(auth, username, password)
                toast.success("Registration Sucessful!");
                setUserUid(res.user.uid);
                if (sessionStorage.getItem("straightPremium") === 'true'){
                    sendEmails(res.user.email, formatName(name), image, title, subject, msg);
                    handleClickOpen();
                }else{
                    writeSubscription(res.user.uid, subscriptionType, amount, message, status, transaction, trxref, reference, duration )
                    writeUserData(res.user.uid, formatName(name), username, phone_number);
                    sendEmails(res.user.email, formatName(name), image, title, subject, msg);
                        navigate('/signin/email');
                }
                // navigate('/signin/email'); 
            } catch (error) {
                if (error.message === "Firebase: Error (auth/email-already-in-use)."){
                    toast.error("Already Rigistered");
                        navigate('/signin/email');
                }
            }finally{
                setIsLoading(false)
            }
        }
    };

    const increaseSize = () => {
        const newDuration = duration + 1;
        setDuration(newDuration);
        setFinalAmount((premiumMonthlyPrice * newDuration) * 100)
    };

    const decreaseSize = () => {
        const newDuration = Math.max(duration - 1, 1);
        setDuration(newDuration);
        setFinalAmount(finalAmount - (premiumMonthlyPrice * 100))
    };

    const config = {
        reference: (new Date()).getTime().toString(),
        email: username,
        amount: finalAmount, 
        publicKey: publicKey,
        secretKey: secretKey,
        currency: 'GHS',
    };

    const handlePaystackSuccessAction = (reference) => {
        setRef(reference)
        setIsDisabled(false);
        setIsDisabledColor("btn btn-success")
        
    };

    const completePurchase = () => {
        const amt = finalAmount/100;
        const subscription = "Premium";
        sessionStorage.setItem("subscriptionType", subscription);
        writeSubscription(user_Uid, subscription, amt, ref.message, ref.status, ref.transaction, ref.trxref, ref.reference, duration );
        writeUserData(user_Uid, formatName(name), username, phone_number);
        sendEmails(username, formatName(name), image1, title1, subject1, msg1);
        navigate("/signin/email");
        sessionStorage.removeItem("straightPremium");
    }
  
    const handlePaystackCloseAction = () => {
    setIsDisabled(true);
    setIsDisabledColor("btn btn-secondary")
    console.log('closed')
    };
  
    const componentProps = {
        ...config,
        text: 'Continue',
        onSuccess: (reference) => handlePaystackSuccessAction(reference),
        onClose: handlePaystackCloseAction,
    };


  return (
    <div className='container-fluid sign-in-bg' >
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="100vh"
        >
            <img className='mb-4' src={logo} alt="logo" width={80} height={80} />
            <h3 className='mb-3 text-white text-center'>Sign up with email</h3>
            <form className='shadow p-3 bg-white rounded-3 w-25' onSubmit={handleSubmit}>
                <TextField
                    label="Full name"
                    placeholder='Full name'
                    type="text"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"> <Person/> </InputAdornment>,
                    }}
                />
                <TextField
                    label="Email"
                    placeholder='Email'
                    type="email"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"> <Email/> </InputAdornment>,
                    }}
                />
                <div className="mb-3">
                    <FormLabel>Phone Number</FormLabel>
                    <PhoneInput placeholder='024 123 4567' defaultCountry='GH' className='phone-input' limitMaxLength={true} value={phone_number} onChange={setPhone} required />
                </div>
                <TextField
                    label="Password"
                    placeholder='Password'
                    type={showPassword ? 'text' : 'password'}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"> <Lock/> </InputAdornment>,
                        endAdornment:
                        <InputAdornment position='end' >
                            <IconButton 
                                aria-label='toggle password visibility' 
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                >
                                {showPassword ? <VisibilityOff/> : <Visibility/> }
                            </IconButton>
                        </InputAdornment>
                    }}
                />
                <button type='submit' style={{ marginTop: '16px' }} className='w-100 btn btn-primary'  >Sign Up </button>
            </form>
                <div className='mb-3 mt-3'>
                    <p className='text-white' > {checkPasswordStrength(password)} </p>
                </div>
                <div className='sign-email mb-2 text-center'>
                    <span className='text-white' >Already have an account? </span><a href="/signin/email">Sign in</a>
                </div>
                <div className="text-center sign-email">
                    <p className='text-white' >By signing up with QR GenN, you agree to our <span><a href="" data-bs-toggle='modal' data-bs-target='#terms'>terms of service</a></span>.</p>
                </div>
        </Box>
        {/* Modal  */}

        <div className="modal fade" id="payment" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            <div className="modal-header">
                <h1 className="modal-title fs-5" id="staticBackdropLabel">Order Summary</h1>
                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button> */}
            </div>
            <div className="modal-body">
                <div className="summary">
                    <div className='d-flex align-items-center justify-content-between mb-4' >
                        <h2 className='fw-bold text-success'>GH₵{finalAmount/100}</h2>
                        <h2 className='fw-bold' >{duration} month(s)</h2>
                    </div>
                    <div>
                        <div className='subsName' >
                            <div className='d-flex align-items-center mb-4'>
                                <img src={subscriptionLogo} width={50} height={50} alt="" />
                                <h6 className='ms-2'>{subscription} Monthly Plan</h6>
                            </div>
                            <hr />
                            <div>
                                <h6>Increase number of months</h6>
                                <div className='text-end' >
                                    
                                </div>
                                <div className='d-flex align-items-center justify-content-between mb-4' >
                                    <button className='btn btn-primary' onClick={decreaseSize} disabled={(finalAmount/100) === 50} ><RemoveCircle  color='#ffffff' /></button>
                                    <button className='btn btn-primary' onClick={increaseSize} ><AddCircle  color='#ffffff' /></button>
                                </div>
                            </div>
                            <hr />
                            <div className='d-flex align-items-center justify-content-between mb-3' >
                                <h6>Due today</h6>
                                <h6 className='fw-bold' >GH₵{finalAmount/100}</h6>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
            <div className="modal-footer">
                <PaystackButton className="btn btn-primary"  {...componentProps} />
                <button 
                    className={isDisabledColor} 
                    disabled={IsDisabled} 
                    data-bs-dismiss="modal" 
                    aria-label="Close"
                    onClick={completePurchase} 
                >
                    Complete Purchase
                </button>
            </div>
            </div>
        </div>
        </div>
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onClose(event, reason)
                }
            }}
            fullScreen={fullScreen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableEscapeKeyDown = {true}
        >
            <DialogContent>
                <button className='btn btn-primary' data-bs-toggle='modal' data-bs-target='#payment' onClick={handleClose} >Proceed to Make Payment</button>
            </DialogContent>

        </Dialog>
        <div className="modal fade" id="terms" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            <div className="modal-header">
                <h1 className="modal-title fs-5" id="staticBackdropLabel">Terms of Service</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
            </div>
            <div className="modal-body">
            <h4>Terms of Service for QR GenN</h4>

                <p>Last Updated: 23/04/2024</p>

                <p>Thank you for choosing QR GenN, a QR code generation system provided by QR GenN. These Terms of Service govern your use of the website <a href="http://qrgenn.techieszon.com">qrgenn.techieszon.com</a>.</p>

                <h4>1. Acceptance of Terms</h4>

                <p>By accessing or using the Service, you agree to be bound by these Terms of Service and all applicable laws and regulations. If you do not agree with any of these terms, you are prohibited from using or accessing the Service.</p>

                <h4>2. Use License</h4>

                <p>Permission is granted to use the Service for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title.</p>

                <h4>3. User Conduct</h4>

                <p>When using the Service, you agree to comply with all applicable laws and regulations and to not engage in any conduct that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, or otherwise objectionable.</p>

                <h4>4. Intellectual Property</h4>

                <p>All content included in the Service, including but not limited to text, graphics, logos, images, and software, is the property of QR GenN or its licensors and is protected by copyright and other laws.</p>

                <h4>5. Limitations</h4>

                <p>In no event shall QR GenN or its suppliers be liable for any damages arising out of the use or inability to use the Service, even if QR GenN has been notified orally or in writing of the possibility of such damage.</p>

                <h4>6. Indemnification</h4>

                <p>You agree to indemnify and hold QR GenN harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your use of the Service, your violation of these Terms of Service, or your violation of any rights of another.</p>

                <h4>7. Modifications</h4>

                <p>QR GenN may revise these Terms of Service at any time without notice. By using the Service, you are agreeing to be bound by the then-current version of these Terms of Service.</p>

                <h4>8. Governing Law</h4>

                <p>These Terms of Service shall be governed by and construed in accordance with the laws of Ghana, without regard to its conflict of law provisions.</p>

                <h4>9. Contact Us</h4>

                <p>If you have any questions about these Terms of Service, please contact us at <a href="mailto:qrgenn@gmail.com">qrgenn@gmail.com</a>.</p>

            </div>
            
            </div>
        </div>
        </div>
    </div>
  )
}
