import React,{createContext, useState, useEffect} from 'react';
import { auth, db } from '../firebase';
import {ref, onValue,} from "firebase/database";
import { useNavigate } from 'react-router-dom';
import ToastService from "react-material-toast";
import premium from '../assets/premium.png'


const toast = ToastService.new({
    place: "topRight",
    duration: 1,
    maxCount: 1,
  });

export const SharedFunctions = createContext({});

export default function Functions({children}) {
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState();
    const [isLoading, setLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [userInfo, setUserInfo] = useState({});
    const [userSubscriptionData, setUserSubscriptionData] = useState({});
    const [user_id, setUserID] = useState("");
    const userName = sessionStorage.getItem("name");
    const phoneNumner = sessionStorage.getItem("phone");
    const userEmail = sessionStorage.getItem("email");
    const [open, setOpen] = useState(false);
    let emailSent = false;
    const baseURL = 'https://mailserver-jz3h.onrender.com';
    const publicKey = 'pk_live_ba5d20609535331ae50062eceee104bdcf95e794';
    const secretKey = 'sk_live_71c0c52fbe60457648d7994abd101ec764910faa';

    // const publicKey = 'pk_test_6175ed7431e9b03ef62a2cf147e0596792a9bc85';
    // const secretKey = 'sk_test_c51b7985fac7e18e6d9ef08a389158250584307b';
    const token = sessionStorage.getItem("token");


    // pricing variables 
    const [premiumMonthlyPrice, setPremiumMonthlyPrice] = useState(50);
    const [duration, setDuration] = useState(1);
    const [finalAmount, setFinalAmount] = useState(0)
    const [subscription, setSubscription] = useState('')
    const [subscriptionLogo, setSubscriptionLogo] = useState()
    

    useEffect(() => {
        setLoading(true);
    
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setCurrentUser(user);
    
            if (user) {
                sessionStorage.setItem("email", user.email);
                const userID = user.uid;
                setUserID(user.uid);

                const fetchDataForUser = (uniqueId) => {
                    const reference = ref(db, 'users/' + uniqueId);
                    onValue(reference, (snapshot) => {
                        const userData = snapshot.val();
                        if (userData) {
                            const userId = Object.keys(userData)[0];
                    
                            if (userId) {
                                const userValues = userData[userId];
                                setUserInfo(userValues);
                            } else {
                                setUserInfo({});
                            }
                        } else {
                            setUserInfo({});
                        }
                    });
                    
                };
    
                const fetchQRDataForUser = (uniqueId) => {
                    const reference = ref(db, 'qrcodes/' + uniqueId);
                    onValue(reference, (snapshot) => {
                        const qrData = snapshot.val();
                        if (qrData) {
                            setTableData(Object.values(qrData));
                        } else {
                            setTableData([]);
                        }
                    });
                };

                const fetchUserSubscription = (userId) => {
                    const reference = ref(db, 'subscription/' + userId);
                    onValue(reference, (snapshot) => {
                        const userSubscription = snapshot.val();

                        if (userSubscription) {
                            sessionStorage.setItem("subscriptionType", userSubscription.subscriptionType);
                            setUserSubscriptionData(Object.values(userSubscription));
                            setUserSubscriptionData(userSubscription);
                            // console.log("User Subs: ",userSubscription.subscriptionType)
                        } else {
                            setUserSubscriptionData([]);
                        }
                    });
                };
                
                fetchDataForUser(userID);
                fetchQRDataForUser(userID);
                fetchUserSubscription(userID);
            }
    
            setLoading(false);
        });
    
        return () => unsubscribe();
       
    }, [user_id]);

    const handleClose = (event, reason) => {
        if (userSubscriptionData.subscriptionType === 'Premium' || reason === 'clickaway' || reason === 'timeout') {
            return;
          }
    
        setOpen(false);
      };

    const upGradeFreemium = () => {
        if(userSubscriptionData.subscriptionType === "Freemium" && tableData.length === 2){
            setOpen(true);
        }else{
            handleClose()
        }
    }

    const disableDelete = () => {
        if(userSubscriptionData.subscriptionType === "Freemium"){
            setOpen(true);
        }else{
            handleClose()
        }
    }

    const sendEmails = async (email, name, image, title, subject, msg) => {
        var templateParams = {
            to_name:  name,
            to: email,
            image: image,
            title: title,
            subject: subject,
            message: msg
          };
          const response = await fetch(`${baseURL}/api/send-email`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(templateParams),
          });
          
          if (!response.ok) {
            const errorData = await response.json(); 
            console.error('Failed to send email:', errorData);
          } else {
            console.log('Email sent successfully!');
          }          
    };

    // console.log(currentUser && currentUser.accessToken)
    

    const nextBillingDate = () => {
        const subsDate = new Date(userSubscriptionData.subscription_created_at);
        const subsDuration = (31 * userSubscriptionData.numOfMonths);
    
        const nextBillingDate = new Date(subsDate);
        if (subsDuration > 365){
            nextBillingDate.setDate(subsDate.getDate() + (subsDuration - 7));
        }else{
            nextBillingDate.setDate(subsDate.getDate() + subsDuration);
        }
        const formattedDate = nextBillingDate.toLocaleDateString();
        return formattedDate;
    }

    useEffect(() => {
        sessionStorage.setItem("tableData", tableData.length)
        // eslint-disable-next-line
    }, [tableData]);

    const handleSignOut = async () => {
        setLoading(true)
        try {
          await auth.signOut();
          toast.success('Sign out successful');
          sessionStorage.removeItem("subscriptionType");
          sessionStorage.removeItem("straightPremium");
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("email");
          navigate('/')
          window.location.reload();
            
        } catch (error) {
          console.error('Error signing out:', error.message);
        }finally{
            setLoading(false)
        }
    };

    const subscriptionExpiry = () => {
        const today = new Date();
        const formattedToday = today.toLocaleDateString();
        if (userSubscriptionData.subscriptionType === "Premium" && new Date(formattedToday) >= nextBillingDate()){
            setOpen(true);
        }else{
            handleClose();
        }
    }

    const premiumCalc = () => {
        setSubscription('Premium');
        setSubscriptionLogo(premium)
        const premiumAmountWithoutVAT = premiumMonthlyPrice * duration;
        setFinalAmount((premiumAmountWithoutVAT * 100).toFixed(0));
    };

    const formatName = (name) => {
        const words = name.trim().split(/\s+/);
        const formattedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
        return formattedWords.join(' ');
    };

  return (
    <SharedFunctions.Provider 
        value={{baseURL, currentUser, tableData, userInfo, userSubscriptionData, 
                user_id, isLoading, upGradeFreemium, disableDelete, 
                open, handleClose, handleSignOut, nextBillingDate, 
                subscriptionExpiry, userName, phoneNumner, userEmail, 
                emailSent, sendEmails, premiumMonthlyPrice, setPremiumMonthlyPrice, 
                duration, setDuration, finalAmount, setFinalAmount, subscription, subscriptionLogo,
                premiumCalc, publicKey, secretKey, token, formatName}}>
        {children}
    </SharedFunctions.Provider>
  )
}
