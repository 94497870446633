import React, {useContext, useEffect, useState } from 'react';
import NavBar from '../components/NavBar';
import { db } from '../firebase';
import { get, ref } from 'firebase/database';
import ReactPaginate from 'react-paginate';
import {Backdrop, CircularProgress, IconButton} from '@mui/material';
import { CheckCircleRounded, EmailRounded, UpgradeRounded } from '@mui/icons-material';
import { SharedFunctions } from '../shared/Functions';
import ToastService from "react-material-toast";

const toast = ToastService.new({
  place: "topRight",
  duration: 1,
  maxCount: 1,
});


function AdminDashboard() {
  const [users, setUsers] = useState([]);
  const [subscription, setSubscription] = useState([]);
  // const [qrCodes, setQrCodes] = useState([]);
  const itemsPerPage = 7; 
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoadings, setIsLoading] = useState(false);
  const {sendEmails, formatName} = useContext(SharedFunctions);

  useEffect(() => {
    const fetchUsers = async () => {
      const usersRef = ref(db, 'users');
      setIsLoading(true);
      try {
        const snapshot = await get(usersRef);
        if (snapshot.exists()) {
          const usersData = snapshot.val();
          const flattenedUsers = Object.values(usersData).map(user => Object.values(user)[0]);
          setUsers(flattenedUsers);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }finally{
        setIsLoading(false);
      }
    };

    const fetchUsersSubscription = async () => {
      const usersRef = ref(db, 'subscription');
      setIsLoading(true);
      try {
        const snapshot = await get(usersRef);
        if (snapshot.exists()) {
          const subscriptionData = snapshot.val();
          setSubscription(Object.values(subscriptionData));
        }
      } catch (error) {
        console.error("Error fetching Subscription:", error);
      }finally{
        setIsLoading(false);
      }
    };

    // const fetchUsersQrCodes = async () => {
    //   const usersRef = ref(db, 'qrcodes');
    //   setIsLoading(true);
    //   try {
    //     const snapshot = await get(usersRef);
    //     if (snapshot.exists()) {
    //       const qrCodeData = snapshot.val();
    //       const flattenedQrCodes = Object.values(qrCodeData).map(code => Object.values(code)[0]);
    //       setQrCodes(flattenedQrCodes);
    //     }
    //   } catch (error) {
    //     console.error("Error fetching Qr Codes:", error);
    //   }finally{
    //     setIsLoading(false);
    //   }
    // };

    fetchUsers();
    fetchUsersSubscription();
    // fetchUsersQrCodes();
  }, []);

  const mergedData = users.map(user => ({
    ...user,
      ...subscription.find(sub => sub.user_Id === user.user_Id),
  }));
  
  const sortedData = mergedData.sort((a, b) => new Date(b.subscription_created_at) - new Date(a.subscription_created_at));
  // console.log("Merged: ",mergedData)

    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedData = mergedData.slice(startIndex, endIndex);

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

      const shouldDisplayPagination = mergedData.length > itemsPerPage;

      const calculateNextBillingDate = (created_at, numOfMonths) => {
        const subsDate = new Date(created_at);
        const subsDuration = 31 * numOfMonths;
    
        const nextBillingDate = new Date(subsDate);
        if (subsDuration > 365) {
            nextBillingDate.setDate(subsDate.getDate() + (subsDuration - 7));
        } else {
            nextBillingDate.setDate(subsDate.getDate() + subsDuration);
        }
    
        return nextBillingDate.toLocaleDateString();
    };

    const isSubscriptionActive = (nextBillingDate) => {
      const today = new Date();
      const formattedToday = today.toLocaleDateString();
      return new Date(formattedToday) < new Date(nextBillingDate);
    };

    const upgradeEmail = async (user) => {
      setIsLoading(true);
  
      const image = "https://i.imgur.com/34StdsQ.png";
      const title = "Upgrade Subscription";
      const subject =  "Upgrade Subscription";
      const msg = "You are currently on the Freemium Subscription package. Upgrade to Premium to continue using our services. https://qrgenn.techieszon.com/#pricing.";
  
      try {
          await sendEmails(user.email, formatName(user.name), image, title, subject, msg);
          toast.success("Email Sent Sucessfully!");
          setIsLoading(false);
      } catch (error) {
          toast.success("Email  Not Sent!");
          console.error("Error sending email:", error);
      }finally{
        setIsLoading(false);
      }
    }
  
  const expiredEmail = async (user) => {
    setIsLoading(true);

    const image = "https://i.imgur.com/eMDV6Rz.png";
    const title = "Subscription Expired";
    const subject =  "Subscription Expired";
    const msg = "Your Subscription has expired. Renew now to continue enjoying our services. https://qrgenn.techieszon.com/#pricing."

    try {
        await sendEmails(user.email, formatName(user.name), image, title, subject, msg);
        toast.success("Email Sent Sucessfully!");
        setIsLoading(false);
    } catch (error) {
        toast.success("Email  Not Sent!");
        console.error("Error sending email:", error);
    }finally{
      setIsLoading(false);
    }
  }

  return (
    <div>
      <NavBar />
      <div className="container mt-3">
          <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoadings}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <h4>All Users</h4>
        {displayedData.length === 0 ? (
          <h6 className='text-center' >No data to display</h6>
        ):(
          <>
            <div className='table-responsive'>
              <table className='table table-striped table-hover' >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Subscription Type</th>
                    <th>Subscription Date</th>
                    <th>Duration</th>
                    <th>Next Billing Date</th>
                    {/* <th>QR Code</th> */}
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                {sortedData.map((user, index) => {
                  const nextBillingDate = calculateNextBillingDate(user.subscription_created_at,user.numOfMonths);
                  return(
                    <tr key={index} style={{ verticalAlign: 'middle' }}>
                      <td>{formatName(user.name)}</td>
                      <td>{user.email}</td>
                      <td>{user.phone}</td>
                      <td>{user.subscriptionType}</td>
                      <td>{new Date(user.subscription_created_at).toLocaleDateString()}</td>
                      <td>{user.numOfMonths}</td>
                      <td>{user.numOfMonths ? nextBillingDate : 'N/A'}</td>
                      {/* <td>{user.url}</td> */}
                      <td>{user.numOfMonths ? (isSubscriptionActive(nextBillingDate) ? 'Active' : 'Expired') : 'Upgrade'}</td>
                      <td> 
                          {user.numOfMonths ? (
                              isSubscriptionActive(nextBillingDate) ? <IconButton onClick={() => upgradeEmail(user)} ><CheckCircleRounded style={{color: 'green'}} /></IconButton> : <IconButton onClick={() => expiredEmail(user)} ><EmailRounded style={{color: 'blue'}} /></IconButton>
                          ) : (
                              <IconButton onClick={() => upgradeEmail(user)} ><UpgradeRounded style={{color: 'red'}} /></IconButton>
                          )}
                      </td>
                    </tr>
                  ) 
              })}
                </tbody>
              </table>
              {shouldDisplayPagination && (
                    <ReactPaginate
                    pageCount={Math.ceil(mergedData.length / itemsPerPage)}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    onPageChange={handlePageChange}
                    containerClassName="pagination"
                    activeClassName="active"
                    />
                )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default AdminDashboard;
