import React, {useState, useContext} from 'react';
import logo from '../assets/logo.png';
import avatar from '../assets/avatar.png';
import { Backdrop, CircularProgress, IconButton, Tooltip, Menu, MenuItem, Divider, ListItemIcon } from '@mui/material';
import { Logout, Dashboard } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { SharedFunctions } from '../shared/Functions';


export default function NavBar() {
    const {currentUser, handleSignOut, isLoading, token} = useContext(SharedFunctions)
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
  

  return (
    <nav className="navbar navbar-expand-lg bg shadow  sticky-top">
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        <div className="container">
            <a className="navbar-brand text-white d-flex align-items-center" href="/">
                <img className='logo' src={logo} alt='logo' />
                <div className='ms-3 me-5 name' >
                    <h2 className='mb-0 fw-bold'>QR GenN</h2>
                    <p className='mb-0' >Scan, Share, and Connect!!</p>
                </div>
            </a>
            <button className="navbar-toggler bg-white py-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
                    <li className="nav-item">
                        <a className="nav-link" href="/">Home</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/#pricing">Pricing</a>
                    </li>
                    {token && (
                        <li className="nav-item">
                            <a className="nav-link" href="/dashboard">Dashboard</a>
                        </li>
                    )}
                    <li className="nav-item">
                        <a className="nav-link" href="./#contact">Contact</a>
                    </li>
                </ul>
            
            <div className="d-flex">
                {token ? (
                    <>
                        <Tooltip title="Account settings">
                            <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <div>
                                    <img className='rounded-circle' src={(currentUser && currentUser.photoURL) || avatar} alt='user' width={40}  />
                                </div>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                                },
                                '&::before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                                },
                            },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem onClick={() => {navigate('/dashboard')}} >
                            <ListItemIcon>
                                <Dashboard fontSize="small" />
                            </ListItemIcon>  
                            Dashboard
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={handleSignOut}>
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                                Sign Out
                            </MenuItem>
                        </Menu>
                    </>
                ) : (
                    <>
                        <a href='/signin/email' className="btn btn-light me-2" type="submit">Sign In</a>
                        <a href='/signup/email' className="btn btn-light" type="submit">Sign up</a>
                    </>
                )}
            </div>
            </div>
        </div>
    </nav>
  )
}
