import React, {useState, useContext, useEffect} from 'react';
import { TextField, Select, MenuItem, InputLabel, FormControl, FormLabel} from '@mui/material';
import { QRCodeCanvas } from 'qrcode.react';
import {v4} from "uuid";
import { writeData } from '../firebase';
import { Facebook, Instagram, LinkedIn, Save, WhatsApp, X } from '@mui/icons-material';
import { SharedFunctions } from '../shared/Functions';
import { Brush, ColorLens, AddCircle, RemoveCircle } from '@mui/icons-material';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import whatsAppLogo from '../assets/whatsapp.png';
import facebookLogo from '../assets/facebook-new.png';
import xLogo from '../assets/twitterx--v1.png';
import instagramLogo from '../assets/instagram-new.png';
import linkedInLogo from '../assets/linkedin.png';
import snapchatLogo from '../assets/snapchat--v1.png';
import tiktokLogo from '../assets/tiktok.png';


function SocialMedia() {
    const {user_id, upGradeFreemium, userSubscriptionData, subscriptionExpiry, nextBillingDate} = useContext(SharedFunctions);
    const id = v4();
    const today = new Date();
    const formattedToday = today.toLocaleDateString();
    const [socialMediaLink, setSocialMediaLink] = useState('');
    const [socialMedia, setSocialMedia] = useState('');
    const [whatsApp, setWhatsAppLink] = useState('');
    const [facebook, setFacebookLink] = useState('');
    const [twitter, setTwitterLink] = useState('');
    const [instagram, setInstagramLink] = useState('');
    const [linkedin, setLinkedInLink] = useState('');
    const [snapchat, setSnapchatLink] = useState('');
    const [tiktok, setTikTokLink] = useState('');

    const [qrSize, setQrSize] = useState(parseInt(288));
    const [bgColor, setBgColor] = useState('#ffffff');
    const [fgColor, setFgColor] = useState('#000000');
    const [logo, setLogo] = useState(null); 
    const [show, setShow] = useState("");

    const increaseSize = () => {
        const newSize = qrSize + 5;
        setQrSize(newSize);
      };

      const decreaseSize = () => {
        const newSize = Math.max(qrSize - 5, 288);
        setQrSize(newSize);
      };

      const handleColorChange = (e) => {
        setBgColor(e.target.value);
      };

      const handleFgColorChange = (e) => {
        setFgColor(e.target.value);
      };

      const toggleColorPicker = () => {
        setShow("Background");
      };

      const toggleColorPicker1 = () => {
        setShow("Foreground");
      };


    const handleSocialMediaChange = (event) => {
        const newSocial = event.target.value;
        if(sessionStorage.getItem("tableData") === "2" && sessionStorage.getItem("subscriptionType") === "Freemium"){
            upGradeFreemium()
        }else if(userSubscriptionData.subscriptionType === "Premium" && new Date(formattedToday) === nextBillingDate()){
            subscriptionExpiry();
        }else{
            setSocialMedia(newSocial);
        }
    };

    
    const handleSocialMediaLinkChange = () => {
        if (socialMedia === "WhatsApp"){
            setSocialMediaLink(`https://wa.me/${whatsApp}`)
            setLogo(whatsAppLogo)
        }else if (socialMedia === "Facebook"){
            setSocialMediaLink(`https://web.facebook.com/${facebook}`)
            setLogo(facebookLogo)
        }else if (socialMedia === "Twitter(X)"){
            setSocialMediaLink(`https://twitter.com/${twitter}`)
            setLogo(xLogo)
        }else if (socialMedia === "Instagram"){
            setSocialMediaLink(`https://www.instagram.com/${instagram}`)
            setLogo(instagramLogo)
        }else if (socialMedia === "LinkedIn"){
            setSocialMediaLink(`https://www.linkedin.com/in/${linkedin}`)
            setLogo(linkedInLogo)
        }else if (socialMedia === "Snapchat"){
            setSocialMediaLink(`https://www.snapchat.com/${snapchat}`)
            setLogo(snapchatLogo)
        }else if (socialMedia === "TikTok"){
            setSocialMediaLink(`https://www.tiktok.com/@${tiktok}`)
            setLogo(tiktokLogo)
        }
    }

    useEffect(() => {
        handleSocialMediaLinkChange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socialMedia, whatsApp, facebook, twitter, instagram, linkedin, snapchat, tiktok]);

    const handleDownloadClick = () => {
        const qrCodeCanvas = document.getElementById('qrgen');
        const dataUrl = qrCodeCanvas.toDataURL('image/png');
        writeData(user_id, socialMediaLink, dataUrl, id)
    };


  return (
    <div>
        <div className="py-3">
            <div className="row">
                <div className="col-sm-8">
                    <h6 className='mb-3'>Select Social Media Network.</h6>
                    <div className='mb-3'>
                        <FormControl fullWidth required >
                            <InputLabel id="demo-simple-select-helper-label">Social Media Network</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={socialMedia}
                                label="Social Media Network"
                                onChange={handleSocialMediaChange}
                                required
                            >
                            <MenuItem value="WhatsApp"><WhatsApp style={{color: "#25D366"}}/>WhatsApp</MenuItem>
                            <MenuItem value="Facebook"><Facebook style={{color: "#1877F2"}}/>Facebook</MenuItem>
                            <MenuItem value="Twitter(X)"><X style={{color: "#000000"}}/>Twitter(X)</MenuItem>
                            <MenuItem value="Instagram"><Instagram style={{color: "#cd486b"}}/>Instagram</MenuItem>
                            <MenuItem value="LinkedIn"><LinkedIn style={{color: "#0072b1"}}/>LinkedIn</MenuItem>
                            <MenuItem value="Snapchat"><img width="20" height="20" src="https://img.icons8.com/external-tal-revivo-color-tal-revivo/20/external-snapchat-a-multimedia-messaging-app-used-globally-logo-color-tal-revivo.png" alt="external-snapchat-a-multimedia-messaging-app-used-globally-logo-color-tal-revivo"/>Snapchat</MenuItem>
                            <MenuItem value="TikTok"><img width="20" height="20" src="https://img.icons8.com/ios-filled/20/tiktok--v1.png" alt="tiktok--v1"/>TikTok</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <form>
                    {socialMedia === 'WhatsApp' && (
                        <div className="mb-3">
                            <FormLabel>WhatsApp Number</FormLabel>
                            <PhoneInput placeholder='024 123 4567' defaultCountry='GH' className='phone-input' limitMaxLength={true} value={whatsApp} onChange={setWhatsAppLink} required />
                        </div>
                    )}
                    {socialMedia === 'Facebook' && (
                        <TextField 
                            label='Facebook Name'
                            type='text'
                            placeholder='Facebook Name'
                            variant='outlined'
                            value={facebook}
                            onChange={(e) => setFacebookLink(e.target.value.trim())}
                            fullWidth
                            className='mb-3'
                            required
                        />
                    )}
                    {socialMedia === 'Twitter(X)' && (
                        <TextField 
                            label='Twitter(X) Name'
                            type='text'
                            placeholder='Twitter(X) Name'
                            variant='outlined'
                            value={twitter}
                            onChange={(e) => setTwitterLink(e.target.value.trim())}
                            fullWidth
                            className='mb-3'
                            required
                        />
                    )}

                    {socialMedia === 'Instagram' && (
                        <TextField 
                            label='Instagram Name'
                            type='text'
                            placeholder='Instagram Name'
                            variant='outlined'
                            value={instagram}
                            onChange={(e) => setInstagramLink(e.target.value.trim())}
                            fullWidth
                            className='mb-3'
                            required
                        />
                    )}

                    {socialMedia === 'LinkedIn' && (
                        <TextField 
                            label='LinkedIn Name'
                            type='text'
                            placeholder='LinkedIn Name'
                            variant='outlined'
                            value={linkedin}
                            onChange={(e) => setLinkedInLink(e.target.value.trim())}
                            fullWidth
                            className='mb-3'
                            required
                        />
                    )}

                    {socialMedia === 'Snapchat' && (
                        <TextField 
                            label='Snapchat Name'
                            type='text'
                            placeholder='Snapchat Name'
                            variant='outlined'
                            value={snapchat}
                            onChange={(e) => setSnapchatLink(e.target.value.trim())}
                            fullWidth
                            className='mb-3'
                            required
                        />
                    )}

                    {socialMedia === 'TikTok' && (
                        <TextField 
                            label='TikTok Name'
                            type='text'
                            placeholder='TikTok Name'
                            variant='outlined'
                            value={tiktok}
                            onChange={(e) => setTikTokLink(e.target.value.trim())}
                            fullWidth
                            className='mb-3'
                            required
                        />
                    )}
                        
                    </form>
                    
                    { (whatsApp || facebook || twitter || instagram || linkedin || snapchat || tiktok) && (
                    <div className="customization d-none d-lg-block mt-4">
                        <h3>Customization Panel</h3>
                        <div className='d-flex flex-column flex-lg-row justify-content-between' >
                            <button className='btn btn-primary mb-3' onClick={increaseSize} >Size <AddCircle/> </button>
                            <button className='btn btn-primary mb-3' onClick={decreaseSize} >Size <RemoveCircle/> </button>
                            <button className='btn btn-primary mb-3' onClick={toggleColorPicker}>Background Color <Brush/> </button>
                            <button className='btn btn-primary mb-3' onClick={toggleColorPicker1} >Foreground Color <ColorLens/> </button>
                        </div>
                        {show === "Background" && (
                            <div className='mb-3' >
                                <label className='form-label'>Backgound Color Picker</label>
                                <input type="color" className='form-control' value={bgColor} onChange={handleColorChange} />
                            </div>
                        )}
                        {show === "Foreground" && (
                            <div className='mb-3' >
                                <label className='form-label'>Foreground Color Picker</label>
                                <input type="color" className='form-control' value={fgColor} onChange={handleFgColorChange} />
                            </div>
                        )}
                    </div>
                     )}
                </div>
                <div className="col-sm-4">
                    { (whatsApp || facebook || twitter || instagram || linkedin || snapchat || tiktok) && (
                        <>
                        <h4 className='text-center' >QR Code</h4>
                        <div className='text-center mb-3'>
                            <QRCodeCanvas imageSettings={{src: logo, width: 40, height: 40, excavate: true}} bgColor={bgColor} fgColor={fgColor} size={qrSize} id='qrgen' value={socialMediaLink}  />
                        </div>

                        <div className='text-center'>
                            <button className='btn btn-primary' onClick={handleDownloadClick} >
                                <Save/>
                             Save
                            </button>
                        </div>
                            
                        </>
                    )}
                </div>
            </div>
            { (whatsApp || facebook || twitter || instagram || linkedin || snapchat || tiktok) && (
            <div className="customization d-lg-none mt-4">
                <h3>Customization Panel</h3>
                <div className='d-flex flex-column flex-lg-row justify-content-between' >
                    <button className='btn btn-primary mb-3' onClick={increaseSize} >Size <AddCircle/> </button>
                    <button className='btn btn-primary mb-3' onClick={decreaseSize} >Size <RemoveCircle/> </button>
                    <button className='btn btn-primary mb-3' onClick={toggleColorPicker}>Background Color <Brush/> </button>
                    <button className='btn btn-primary mb-3' onClick={toggleColorPicker1} >Foreground Color <ColorLens/> </button>
                </div>
                {show === "Background" && (
                    <div className='mb-3' >
                        <label className='form-label'>Backgound Color Picker</label>
                        <input type="color" className='form-control' value={bgColor} onChange={handleColorChange} />
                    </div>
                )}
                {show === "Foreground" && (
                    <div className='mb-3' >
                        <label className='form-label'>Foreground Color Picker</label>
                        <input type="color" className='form-control' value={fgColor} onChange={handleFgColorChange} />
                    </div>
                )}
            </div>
            )}
        </div>
    </div>
  )
}

export default SocialMedia