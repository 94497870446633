import './App.css';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Files from './screens/Files';
import Welcome from './screens/Welcome';
import Text from './screens/Text';
import Url from './screens/Url';
import SignUpEmail from './screens/SignUpEmail';
import SignInEmail from './screens/SignInEmail';
import Profile from './screens/Profile';
import PasswordReset from './screens/PasswordReset';
import Dashboard from './screens/Dashboard';
import SignIn from './screens/SignIn';
import SignUp from './screens/SignUp';
import AdminDashboard from './screens/AdminDashboard';
import PrivacyPolicy from './screens/PrivacyPolicy';


function App() {
  return (
    <div className="App">
      <Routes>
            <Route path='/' element={<Welcome />} />
            <Route path='/signup/email' element={<SignUpEmail />} />
            <Route path='/signin/email' element={<SignInEmail />} />
            <Route path='/signin' element={<SignIn />} />
            <Route path='/signup' element={<SignUp />} />
            <Route path='/verify' element={<PasswordReset />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/files' element={<Files />} />
            <Route path='/text'element={<Text />} />
            <Route path='/url' element={<Url />} />
            <Route path='/admin/dashboard' element={<AdminDashboard />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        </Routes>
    </div>
  );
}

export default App;
