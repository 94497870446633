import React from 'react';
import DashNav from '../components/DashNav';

export default function Dashboard() {
  return (
    <div>
        <DashNav/>
    </div>
  )
}
