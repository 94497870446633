import React, { useState } from 'react';
import { TextField, Box, Button, Backdrop, CircularProgress, InputAdornment } from '@mui/material';
import ToastService from "react-material-toast";
import { auth } from '../firebase';
import {sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';
import { Email } from '@mui/icons-material';

const toast = ToastService.new({
    place: "topRight",
    duration: 1,
    maxCount: 1,
  });

export default function PasswordReset() {
    const navigate = useNavigate();
    const [isLoading,  setIsLoading] = useState(false)
    const [email, setEmail] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            await sendPasswordResetEmail(auth, email)
            toast.success("Email verification Sucessful! Check your email.");
            navigate('/signin/email')
        } catch (error) {
            console.log(error)
        }finally{
            setIsLoading(false)
        }
    }

  return (
    <div className='container-fluid sign-in-bg'>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="100vh"
        >
            <img className='mb-4' src={logo} alt="logo" width={80} height={80} />
            <h3 className='mb-3 text-white text-center'>Reset your password</h3>
            <div className='' >
                <p className='text-white text-center' >To reset your password, enter the <br/> email address you use to log in</p>
            </div>
            <form className='shadow p-3 bg-white rounded-3' onSubmit={handleSubmit} >
                <TextField
                    label="Email"
                    type="email"
                    placeholder='Email'
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"> <Email/> </InputAdornment>,
                    }}
                />

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{ marginTop: '16px' }}
                    className='w-100'
                >
                    Get Reset Link
                </Button>
                
            </form>
            
            <div className='mt-3 text-center sign-email'>
                <span className='text-white' >Never mind!</span><a href="/signin/email">Take me back to sign in</a>
            </div>
            
        </Box>
    </div>
  )
}
