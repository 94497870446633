import React, { useState } from 'react';
import { TextField, Box, Button, Backdrop, CircularProgress, InputAdornment, IconButton } from '@mui/material';
import ToastService from "react-material-toast";
import { auth} from '../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { Email, Visibility, VisibilityOff, Lock } from '@mui/icons-material';
import logo from '../assets/logo.png';


const toast = ToastService.new({
    place: "topRight",
    duration: 1,
    maxCount: 1,
  });

export default function SignInEmail() {
    const navigate = useNavigate();
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading,  setIsLoading] = useState(false)
    

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
            setIsLoading(true)
            try {
                const res = await signInWithEmailAndPassword(auth, username, password)
                    toast.success("Login Sucessful!");
                    sessionStorage.setItem("token", res.user.accessToken)
                    navigate('/dashboard')
             
            } catch (error) {
                if (error.message === "Firebase: Error (auth/invalid-credential)."){
                    toast.error("Invalid Credentials");
                }
            }finally{
                setIsLoading(false)
            }
    };

  return (
    <div className='container-fluid sign-in-bg' >
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="100vh"
        >
            <img className='mb-4' src={logo} alt="logo" width={80} height={80} />
            <h3 className='mb-3 text-white text-center' >Sign in to QR GenN</h3>
            <form className='shadow p-3 bg-white rounded-3 w-25' onSubmit={handleSubmit} >
            <TextField
                    label="Email"
                    placeholder='Email'
                    type="email"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"> <Email/> </InputAdornment>,
                    }}
                />
                <TextField
                    label="Password"
                    placeholder='Password'
                    type={showPassword ? 'text' : 'password'}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"> <Lock/> </InputAdornment>,
                        endAdornment:
                        <InputAdornment position='end' >
                            <IconButton 
                                aria-label='toggle password visibility' 
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                >
                                {showPassword ? <VisibilityOff/> : <Visibility/> }
                            </IconButton>
                        </InputAdornment>
                    }}
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{ marginTop: '16px' }}
                    className='w-100'
                >
                    Sign In
                </Button>
            </form>
            <div className='mt-3 mb-4 text-center sign-email'>
                <a href="/verify">Forgot password?</a>
            </div>
            {/* <div className='sign-email mb-5 text-center'>
                <p className='text-white' >Have a Google, Facebook, GitHub or Twitter account?</p>
                <a href="/signin">Sign in with socials</a>
            </div> */}
            <div className='sign-email mb-5 text-center'>
                <span className='text-white' >Don't have an account? </span><a href="/signup/email">Sign up</a>
            </div>
            <div className='text-center'>
                
                
            </div>
        </Box>
        {/* <div className='sign-email terms'>
            <span className='text-white' >By logging in to QR Code Generator, you agree to our </span><a href="/">terms of service</a>
        </div> */}
    </div>
  )
}
