import React,{useContext, useEffect} from 'react';
import { Backdrop, CircularProgress, IconButton } from '@mui/material';
import { Download, Delete, Upgrade } from '@mui/icons-material';
import { deleteDataById } from '../firebase';
import DataTable from 'react-data-table-component';
import { SharedFunctions } from '../shared/Functions';

const customStyles = {
    rows: {
      style: {
        fontSize: "15px",
      },
    },
    headCells: {
      style: {
        fontSize: "18px",
        fontWeight: "bold",
      },
    },
};

export default function MyStuff() {
    const {user_id, tableData, userSubscriptionData, isLoading, upGradeFreemium, disableDelete, nextBillingDate, subscriptionExpiry} = useContext(SharedFunctions)  
    const today = new Date();
    const formattedToday = today.toLocaleDateString();

    useEffect(() => {
        upGradeFreemium();
        subscriptionExpiry();
        // eslint-disable-next-line
    }, [tableData]);


    const handleDelete = (id) =>{
        if(sessionStorage.getItem("subscriptionType") === "Freemium"){
            disableDelete();
        }else if(sessionStorage.getItem("tableData") === "2" && sessionStorage.getItem("subscriptionType") === "Freemium"){
            upGradeFreemium();
        }else if(userSubscriptionData.subscriptionType === "Premium" && new Date(formattedToday) >= nextBillingDate()){
            subscriptionExpiry();
        }else{
            deleteDataById(user_id, id)
        }
    }

    const columns = [
        {
            name: "Item",
            selector: (row) => (
                isValidURL(row.url) ? (
                    /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/.test(row.url) ? (
                        
                        row.url.length > 50 ? (
                            <a href={row.url} target="_blank" rel="noopener noreferrer">
                                {extractFileName(row.url)}
                            </a>
                        ) : (
                            <a href={row.url} target="_blank" rel="noopener noreferrer">
                                {row.url}
                            </a>
                        )
                    ) : (
                        <a href={row.url} target="_blank" rel="noopener noreferrer">
                            {extractFileName(row.url)}
                        </a>
                    )
                    ) : (
                        <span>{truncateText(row.url, 50)}</span>
                    )
            ),
            sortable: true
        },
        { name: "Date", selector: (row) => new Date(row.qrcode_created_at).toLocaleDateString() },
        { name: "QR Code", selector: (row) => <div className='qr-size' ><img id={row.id} src={row.qrCode} alt='QR Code'  /></div>},
        {
            name: "Actions",
            selector: (row) => [
              <IconButton 
                  key={`download-btn-${row.id}`}
                  onClick={() => handleDownloadClick(row.id)}
              >
                  <Download style={{color: 'blue',}} />
              </IconButton>,
              <IconButton 
                  key={`delete-btn-${row.id}`}
                  onClick={() => {handleDelete(row.id)}}
              >
                  <Delete style={{color: 'red',}} />
              </IconButton>
            ],
          },
    ];

    function isValidURL(url) {
        try {
          new URL(url);
          return true;
        } catch (error) {
          return false;
        }
    }
      
    function extractFileName(fileUrl) {
        const urlObject = new URL(fileUrl);
        const filePath = decodeURIComponent(urlObject.pathname);
        const fileName = filePath.split('/').pop();
        return fileName;
    }

    function truncateText(text, maxLength) {
        if (text.length > maxLength) {
          return text.substring(0, maxLength) + '...';
        }
        return text;
    }
      
    const handleDownloadClick = (imageId) => {
        if(sessionStorage.getItem("tableData") === "2" && sessionStorage.getItem("subscriptionType") === "Freemium"){
            upGradeFreemium();
        }else{

            const imageElement = document.getElementById(imageId);

            if (imageElement instanceof HTMLImageElement) {
                const actualWidth = imageElement.naturalWidth;
                const actualHeight = imageElement.naturalHeight;

                console.log(`Actual Size for Image ${imageId}: ${actualWidth} x ${actualHeight}`);

                const downloadLink = document.createElement('a');
                const tempCanvas = document.createElement('canvas');
                const tempContext = tempCanvas.getContext('2d');
                tempCanvas.width = actualWidth;
                tempCanvas.height = actualHeight;
                tempContext.drawImage(imageElement, 0, 0);

                const dataUrl = tempCanvas.toDataURL('image/png');

                downloadLink.href = dataUrl;
                downloadLink.download = `image_${imageId}.png`;

                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            } else {
                console.error(`Element with id "${imageId}" is not an image element.`);
            }


        }
    };

    

    const sortedData = tableData.slice().sort((a, b) => new Date(b.qrcode_created_at) - new Date(a.qrcode_created_at))
    
    return (
    <div className='py-3' >
        <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}>
            <CircularProgress color="inherit" />
        </Backdrop>
        {userSubscriptionData.subscriptionType && (
            <div className='d-flex flex-column flex-lg-row align-items-center justify-content-end ' >
                <h5 className='me-3'>Current Subscription: {userSubscriptionData.subscriptionType}</h5>
                {userSubscriptionData.subscriptionType === "Freemium" && (
                <div className=''>
                    <a href='./#pricing' className='btn btn-primary'><Upgrade /> Upgrade</a>
                </div>
                )}
            </div>
        )}
        
            <DataTable
            columns={columns}
            data={sortedData}
            pagination
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5]}
            customStyles={customStyles}
            striped
            responsive
            highlightOnHover
            progressPending={isLoading}
            defaultSortFieldId="Date"
            progressComponent={
                <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
                >
                <CircularProgress color="inherit" />
                </Backdrop>
            }
            subHeader
        />
        {sortedData.length === 0 && (
            <div className='text-center' data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample" >
                <button className='btn btn-dark' >Start Generating Your QR Codes</button>
            </div>
        )}
       
    </div>
  )
}
