import React, {useState, useContext} from 'react'
import Dropzone from 'react-dropzone'
import { Container, Box, Backdrop, CircularProgress  } from '@mui/material';
import { CloudUploadOutlined, CloudDoneOutlined, Save} from '@mui/icons-material';
import { fileDB} from '../firebase';
import {ref, uploadBytes,getDownloadURL} from "@firebase/storage";
import {v4} from "uuid";
import { QRCodeCanvas } from 'qrcode.react';
import { writeData } from '../firebase';
import { SharedFunctions } from '../shared/Functions';
import { Brush, ColorLens, Image, AddCircle, RemoveCircle } from '@mui/icons-material';
import ToastService from "react-material-toast";


const toast = ToastService.new({
    place: "topRight",
    duration: 1,
    maxCount: 1,
  });

export default function Files() {
    const {user_id, upGradeFreemium, userSubscriptionData, nextBillingDate, subscriptionExpiry} = useContext(SharedFunctions);
    const [uploading, setUploading] = useState(false);
    const [uploadComplete, setUploadComplete] = useState(false);
    const [downloadURL, setDownloadURL] = useState('');
    const id = v4();
    const today = new Date();
    const formattedToday = today.toLocaleDateString();

    const [qrSize, setQrSize] = useState(parseInt(288));
    const [bgColor, setBgColor] = useState('#ffffff');
    const [fgColor, setFgColor] = useState('#000000');
    const [logo, setLogo] = useState(null); 
    const [show, setShow] = useState("");
    

    const increaseSize = () => {
        const newSize = qrSize + 5;
        setQrSize(newSize);
      };

      const decreaseSize = () => {
        const newSize = Math.max(qrSize - 5, 288);
        setQrSize(newSize);
      };

      const handleColorChange = (e) => {
        setBgColor(e.target.value);
      };

      const handleFgColorChange = (e) => {
        setFgColor(e.target.value);
      };

      const toggleColorPicker = () => {
        setShow("Background");
      };

      const toggleColorPicker1 = () => {
        setShow("Foreground");
      };

      const toggleColorPicker2 = () => {
        setShow("Image");
      };

    const handleLogoChange = (e) => {
        const file = e.target.files[0];
      
        if (file) {
          const maxSizeInBytes = 1024 * 1024;
          if (file.size > maxSizeInBytes) {
            toast.error('File size exceeds the limit. Please choose a smaller file.');
            return;
          }
      
          const reader = new FileReader();
          reader.onloadend = () => {
            setLogo(reader.result);
          };
          reader.readAsDataURL(file);
        }
    };

    const handleDrop = async (acceptedFiles) => {
        if(sessionStorage.getItem("tableData") === "2" && sessionStorage.getItem("subscriptionType") === "Freemium"){
            upGradeFreemium()
        }else if(userSubscriptionData.subscriptionType === "Premium" && new Date(formattedToday) >= nextBillingDate()){
            subscriptionExpiry();
        }else{
            if (acceptedFiles && acceptedFiles.length > 0) {
            const currentFile = acceptedFiles[0];
        
            setUploading(true);
            fileName = currentFile.name;
        
            try {
                const imgRef = ref(fileDB, `files/${currentFile.name}`);
                await uploadBytes(imgRef, currentFile);
                const fetchedDownloadURL = await getDownloadURL(imgRef);
                setDownloadURL(fetchedDownloadURL);
        
                setTimeout(() => {
                setUploadComplete(true);
                setUploading(false);
                const delayBeforeReset = 3000;
                setTimeout(() => {
                    setUploadComplete(false);
                }, delayBeforeReset);
                }, 1000);
            } catch (error) {
                console.error('Error:', error);
        
                setTimeout(() => {
                setUploading(false);
                }, 1000);
            }
            }
        }
    };

    const handleDownloadClick = () => {
        const qrCodeCanvas = document.getElementById('qrgen');
        const dataUrl = qrCodeCanvas.toDataURL('image/png');
        writeData(user_id, downloadURL, dataUrl, id)
    };
 

  
  return (
    <div>
        <div className='py-4' >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={uploading}
                >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="row">
                <div className="col-sm-8 upload-container mb-3">
                <Container maxWidth="sm">
                    <Box sx={{ bgcolor: '#cfe8fc', height: '50vh', display: 'flex', alignItems: 'center', }} >
                        <Dropzone onDrop={handleDrop}>
                            {({getRootProps, getInputProps}) => (
                                <section className='dragDrop' >
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {uploadComplete ? (
                                        <>
                                            <CloudDoneOutlined sx={{ fontSize: 200}} />
                                            <h3>Done uploading! Try another one.</h3>
                                        </>
                                        ) : (
                                        <>
                                            <CloudUploadOutlined sx={{ fontSize: 200}} />
                                            <h3>{uploading ? 'Uploading...' : 'Choose a file or drag it here'}</h3>
                                        </>
                                    )}
                                </div>
                                </section>
                            )}
                        </Dropzone>
                    </Box>
                </Container>
                {downloadURL && (
                    <div className="customization d-none d-lg-block mt-4">
                        <h3>Customization Panel</h3>
                        <div className='d-flex flex-column flex-lg-row justify-content-between' >
                            <button className='btn btn-primary mb-3' onClick={increaseSize} >Size <AddCircle/> </button>
                            <button className='btn btn-primary mb-3' onClick={decreaseSize} >Size <RemoveCircle/> </button>
                            <button className='btn btn-primary mb-3' onClick={toggleColorPicker}>Background Color <Brush/> </button>
                            <button className='btn btn-primary mb-3' onClick={toggleColorPicker1} >Foreground Color <ColorLens/> </button>
                            <button className='btn btn-primary mb-3' onClick={toggleColorPicker2} >Insert Logo <Image/> </button>
                        </div>
                        {show === "Background" && (
                            <div className='mb-3' >
                                <label className='form-label'>Backgound Color Picker</label>
                                <input type="color" className='form-control' value={bgColor} onChange={handleColorChange} />
                            </div>
                        )}
                        {show === "Foreground" && (
                            <div className='mb-3' >
                                <label className='form-label'>Foreground Color Picker</label>
                                <input type="color" className='form-control' value={fgColor} onChange={handleFgColorChange} />
                            </div>
                        )}
                        {show === "Image" && (
                            <div className='mb-3' >
                                <label className='form-label'>Upload Icon</label>
                                <input className='form-control' type="file" accept="image/*" onChange={handleLogoChange} />
                                <h6>Icon size should not be more than 1024kb or 1mb</h6>
                            </div>
                        )}
                    </div>
                )} 
                </div>
                <div className="col-sm-4 qr-code text-center">
                    {downloadURL && (
                        <>
                        <h4 className='text-center' >QR Code</h4>
                            <QRCodeCanvas imageSettings={{src: logo, width: 70, height: 70, excavate: true}} bgColor={bgColor} fgColor={fgColor} size={qrSize} level='L' includeMargin={true} id='qrgen' value={downloadURL}  />
                            <div className='text-center'>
                                <button className='btn btn-primary' onClick={handleDownloadClick} >
                                    <Save/>
                                    <span>Save</span>
                                </button>
                            </div>
                        </>
                    )} 
                </div>
            </div>
            {downloadURL && (
            <div className="customization d-lg-none mt-4">
                <h3>Customization Panel</h3>
                <div className='d-flex flex-column flex-lg-row justify-content-between' >
                    <button className='btn btn-primary mb-3' onClick={increaseSize} >Size <AddCircle/> </button>
                    <button className='btn btn-primary mb-3' onClick={decreaseSize} >Size <RemoveCircle/> </button>
                    <button className='btn btn-primary mb-3' onClick={toggleColorPicker}>Background Color <Brush/> </button>
                    <button className='btn btn-primary mb-3' onClick={toggleColorPicker1} >Foreground Color <ColorLens/> </button>
                    <button className='btn btn-primary mb-3' onClick={toggleColorPicker2} >Insert Logo <Image/> </button>
                </div>
                {show === "Background" && (
                    <div className='mb-3' >
                        <label className='form-label'>Backgound Color Picker</label>
                        <input type="color" className='form-control' value={bgColor} onChange={handleColorChange} />
                    </div>
                )}
                {show === "Foreground" && (
                    <div className='mb-3' >
                        <label className='form-label'>Foreground Color Picker</label>
                        <input type="color" className='form-control' value={fgColor} onChange={handleFgColorChange} />
                    </div>
                )}
                {show === "Image" && (
                    <div className='mb-3' >
                        <label className='form-label'>Upload Icon</label>
                        <input className='form-control' type="file" accept="image/*" onChange={handleLogoChange} />
                        <h6>Icon size should not be more than 1024kb or 1mb</h6>
                    </div>
                )}
            </div>
            )} 
        </div>
    </div>
  )
}
export var fileName;