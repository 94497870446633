import { initializeApp } from "firebase/app";
import {getStorage} from "@firebase/storage";
import {getAuth, GoogleAuthProvider, FacebookAuthProvider, GithubAuthProvider, TwitterAuthProvider} from 'firebase/auth';
import { getDatabase, ref, set,push, serverTimestamp, child, get, remove  } from "firebase/database";
import ToastService from "react-material-toast";

const toast = ToastService.new({
  place: "topRight",
  duration: 1,
  maxCount: 1,
});


const firebaseConfig = {
    apiKey: "AIzaSyA1Uh9axLNaPA1Azm6qiqGNpt_GKuax05U",
    authDomain: "qr-gen-3f3af.firebaseapp.com",
    projectId: "qr-gen-3f3af",
    storageBucket: "qr-gen-3f3af.appspot.com",
    messagingSenderId: "57466875490",
    appId: "1:57466875490:web:99babae9971cc2be446b3b"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const fileDB = getStorage(app)
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const facebookProvider = new FacebookAuthProvider();
export const gitHubProvider = new GithubAuthProvider();
export const twitterProvider = new TwitterAuthProvider();
export const db = getDatabase(app);

export const writeData = (uniqueId, downloadLink, qrCode, id) => {
  const db = getDatabase(app);
  const reference = ref(db, 'qrcodes/' + uniqueId);
  const newChildRef = push(reference); 
  set(newChildRef, {
    url: downloadLink,
    qrCode: qrCode,
    id: id,
    user_Id: uniqueId,
    qrcode_created_at: serverTimestamp()
  });
  window.location.reload();
}

export const deleteDataById = async (uniqueId, id) => {
  const db = getDatabase();
  const reference = ref(db, `qrcodes/${uniqueId}`);

  try {
    const snapshot = await get(reference);
    const userData = snapshot.val();

    const matchingChildKey = Object.keys(userData).find(
      (key) => userData[key].id === id
    );

    if (matchingChildKey) {
      const childRef = child(reference, matchingChildKey);
      await remove(childRef);
      toast.success('Data deleted successfully');
    } else {
      toast.error('No matching data found for deletion');
    }
  } catch (error) {
    console.error('Error deleting data:', error.message);
  }
};

export const writeSubscription = (userId, subscriptionType, amount, message, status, transaction, trxref, reference, duration) => {
  set(ref(db, 'subscription/' + userId), {
    subscriptionType: subscriptionType,
    amount: amount,
    message: message,
    status: status,
    transaction: transaction,
    trxref: trxref,
    reference: reference,
    numOfMonths: duration,
    user_Id: userId,
    subscription_created_at: serverTimestamp()
  });
}

export const writeUserData = (uniqueId, name, email, phone) => {
  const db = getDatabase(app);
  const reference = ref(db, 'users/' + uniqueId);
  const newChildRef = push(reference); 
  set(newChildRef, {
    name: name,
    email: email,
    phone: phone,
    user_Id: uniqueId,
    user_created_at: serverTimestamp()
  });
}
